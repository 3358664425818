import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

// interfaces
import { IApiResp } from 'src/app/interfaces/api-resp';

@Injectable({
  providedIn: 'root'
})
export class ImpresorasService {

  apiURL: string = environment.apiURL;

  constructor(
    private http: HttpClient
  ) { }


  getImpresora(id: number) {
    const path = `${this.apiURL}/impresoras/list/${id}`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  }

  listTipo(tipo: string) {
    const heders = true;
    const path = `${this.apiURL}/impresoras/listTipo/${tipo}`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  }


}
