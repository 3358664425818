import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificacionesService } from '../notificaciones.service';
import { environment } from 'src/environments/environment';
import { IApiResp } from 'src/app/interfaces/api-resp';
import { catchError, throwError } from 'rxjs';
import { UsuarioService } from './usuario.service';
import { IArticulo } from 'src/app/interfaces/articulo';
import { IListaOpciones } from 'src/app/interfaces/lista-opciones';




@Injectable({
  providedIn: 'root'
})
export class StockService {

  apiURL: string = environment.apiURL;

  constructor(
    private http: HttpClient,
    private usuarioSrv: UsuarioService,
    private notificacionesSrv: NotificacionesService,
  ) { }

  getLista(_opciones: IListaOpciones = {}) {
    // Devulve la lista filtrada
    const path = `${this.apiURL}/stock/listFiltro`;

    const data = {
      id_almacen: _opciones.id_almacen || null,
      id_cliente: _opciones.id_cliente || null,
      id_articulo: _opciones.id_articulo || null,
      id_ubicacion: _opciones.id_ubicacion || null,
      codUbi: _opciones.codUbi || null,
      ean: _opciones.ean || null,
    };

    return this.http.post<IApiResp>(path, data)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }

  getListaHistorico(_opciones: any = {}) {
    // Devulve la lista del histórico de Stock filtrada
    const path = `${this.apiURL}/stock/listFiltroHistorico`;
    const data = {
      fechaHistorico: _opciones.fechaHistorico || null,
      id_almacen: _opciones.id_almacen || null,
      id_cliente: _opciones.id_cliente || null
    };

    return this.http.post<IApiResp>(path, data)
  }


  // Datos del stock para un artículo y lote
  stockLote(_datos: {
    "id_articulo": number,
    "lote": string
  }) {
    const path = `${this.apiURL}/stock/stockLote`;
    return this.http.post<IApiResp>(path, _datos)
  }


  // Actualiza fechaCaducidad del stock para registros con un artículo y lote
  UpdateFechaCaducidad(_datos: {
    "id_articulo": number,
    "lote": string,
    "fechaCaducidad": Date
  }) {
    const path = `${this.apiURL}/stock/UpdateFechaCaducidad`;
    return this.http.post<IApiResp>(path, _datos)
  }


  getRec(id: number) {
    const path = `${this.apiURL}/stock/list/${id}`;
    const resp = this.http.get<IApiResp>(path)
    return resp;
  }


  newRec(formData: any) {
    const path = `${this.apiURL}/stock/new`;
    return this.http.post<IApiResp>(path, formData)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      );
  }

  update(articulo: IArticulo) {
    const path = `${this.apiURL}/stock/update/${articulo.id}`;
    return this.http.post<IApiResp>(path, articulo)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }


  delete(id: number) {
    const path = `${this.apiURL}/stock/delete/${id}`;
    return this.http.get<IApiResp>(path)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }

  // Mueve TODO el contenido de una ubicación a otra
  stockMueve(datos: any) {
    const path = `${this.apiURL}/stock/stockMueve`;
    return this.http.post<IApiResp>(path, datos)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }

  // Mueve n referencias de un artículo a otra ubicación
  stockMueveArticulo(datos: any) {
    const path = `${this.apiURL}/stock/stockMueveArticulo`;
    return this.http.post<IApiResp>(path, datos)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }

  ///// AUX ////

  errorAuth() {
    this.usuarioSrv.logOut();
    console.log('error', 'No estás autorizado a hacer esta operación');
  }


}
