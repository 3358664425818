import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ModalImagenService {

  fotoDir: string = environment.fotoDir;
  private _ocultarModal: boolean = true;

  public tipo!: 'users' | 'articulos' | 'clientes';
  public id!: string;
  public foto!: string;

  constructor(
  ) { }

  // Declarar EventEmitter 'nuevaImagen' para poder Emitir un evento si se cambia la imagen
  public nuevaImagen: EventEmitter<any> = new EventEmitter<any>();

  get ocultarModal() {
    return this._ocultarModal;
  }

  abrirModal(tipo: 'users' | 'articulos' | 'clientes', id: string, img: string = '_noFoto.png') {
    this._ocultarModal = false;
    this.tipo = tipo;
    this.id = id;
    this.foto = `${this.fotoDir}/${tipo}/${img}`;
  }

  cerrarModal() {
    this._ocultarModal = true;
  }



}
