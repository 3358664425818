import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SelectionType } from '@swimlane/ngx-datatable';

// interfaces
import { IEntrada } from 'src/app/interfaces/entrada';
import { IUbicacion } from 'src/app/interfaces/ubicacion';

// servicios
import { UbicacionesService } from 'src/app/services/datos/ubicaciones.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';

@Component({
  selector: 'app-ubicaciones-cliente',
  templateUrl: './ubicaciones-cliente.component.html',
  styleUrls: ['./ubicaciones-cliente.component.scss']
})
export class UbicacionesClienteComponent implements OnInit, OnChanges {

  @Input() entrada: IEntrada = {};
  @Input() padre: any;              // componente padre

  public ubiListCli: IUbicacion[] = [];

  SelectionType = SelectionType;
  selected = [];

  public loading: boolean = false;
  public avisoApi: string = '';

  messages = {
    emptyMessage: `<span class="text-danger">Sin datos...</span>`,
    totalMessage: 'Total',
    selectedMessage: ''
  }

  constructor(
    private ubicacionesSrv: UbicacionesService,
    private notificacionesService: NotificacionesService
  ) { }

  ngOnInit(): void {
    this.buscaUbisCli();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['entrada']) {
      this.buscaUbisCli();
    }
  }


  buscaUbisCli() {
    // lista de ubicaciones reservadas para este cliente     
    if (!this.entrada.id_cliente) return;

    this.loading = true;
    this.avisoApi = '';

    this.ubicacionesSrv.getListCliente(this.entrada.id_cliente!)
      .subscribe(resp => {
        this.loading = false;

        console.log('_componentes/ubicaciones-cliente ', resp);

        if (resp.error) {
          console.log('_componentes/ubicaciones-cliente ERROR', resp);
          this.avisoApi = resp.mensaje;

        } else {
          this.ubiListCli = resp.data;
          if (!this.ubiListCli.length) this.avisoApi = 'No encuentro ubicaciones asignadas a este cliente';
        }
      })
  }


  public onRowUbiClick(rowEvent: any) {
    this.padre.onRowUbiClick(rowEvent);
  }


}
