import * as Const from '../../shared/constants';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IArtPreparacion } from 'src/app/interfaces/artPreparacion';

// Interfaces 

// Servicios
import { PreparacionesService } from 'src/app/services/datos/preparaciones.service';
import { UicService } from 'src/app/services/datos/uic.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { environment } from 'src/environments/environment';

//librerías
import Swal from 'sweetalert2';

@Component({
  selector: 'app-prepara-recoge',
  templateUrl: './prepara-recoge.component.html',
  styleUrls: ['./prepara-recoge.component.scss']
})
export class PreparaRecogeComponent implements OnInit {

  recogida_cod: string = '';
  public preparacionesList: IArtPreparacion[] = [];
  public totalUbis: number = 0;

  registroActual: any = {
    estado: Const.ESTADO_PREPARACION_PENDIENTE,
    ubiFormat: '',
    ubicacion: 'xxx',
    uic: null,
    cant_recogida: 0,
    cant_alCarro: 1,
    saltar: ''
  }; // Almacena el registro actual.


  currentIndex: number = 0; // Índice del registro actual en el array 
  public uic: string | null = ''; // Identificador de carrito


  public formRec: FormGroup = new FormGroup({});

  public enviado: boolean = false;
  public loading: boolean = false;

  public fotoDir: string = environment.fotoDir;
  public imgResized: string = localStorage.getItem('imgResized') || '/resize/';

  constructor(
    private prepracionSrv: PreparacionesService,
    private fb: FormBuilder,
    private notificacionesSrv: NotificacionesService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private uicSrv: UicService
  ) { }




  ngOnInit(): void {

    this.registroActual.cant_recogida = 0;

    this.formRec = this.fb.group({
      uic: ['', Validators.required],
      ubicacion: ['', Validators.required],
      ean: ['', Validators.required],
      cant_alCarro: [1, [Validators.required, this.cantidadValida.bind(this)]],
      saltar: ['Falta stock']
    })

    // Suscribirse a los parámetros de la url, envían una id, cargar los datos del articulo
    this.activatedRoute.params.subscribe(({ recogida_cod }) => {
      this.recogida_cod = recogida_cod;
      this.cargaPreparacionList();
      //  this.filtraMotivos();   // Filtrar motivos definitivos en preparacionesList
    })


    // Suscribirse a los cambios de ubicacion en el form
    this.formRec?.get('ubicacion')?.valueChanges.subscribe(async (valUbicacion) => {
      if (valUbicacion === this.registroActual.ubicacion) {
        // Pasar el foco a ean
        await this.pausar(400); // Pausa durante 0.2 segundos
        const elementoSiguiente = document.getElementById('ean');
        if (elementoSiguiente) {
          elementoSiguiente.focus();
        }
      }
    });


    // Suscribirse a los cambios de ean en el form
    this.formRec?.get('ean')?.valueChanges.subscribe(async (valEan) => {
      // Verifica si el EAN introducido coincide con el del registro actual
      if (valEan === this.registroActual.ean) {
        await this.pausar(400); // Pausa de 0.4 segundos para evitar actualizaciones rápidas

        const valCantPillada = parseInt(this.formRec.get('cant_alCarro')?.value);

        if (valCantPillada) {
          const numCant_recogida = parseInt(this.registroActual.cant_recogida);
          const numCant_reserva = parseInt(this.registroActual.cant_reserva);
          const numStockUbi = parseInt(this.registroActual.stockUbi);

          // Validar que la cantidad recogida no exceda la cantidad de reserva
          if (numCant_recogida + valCantPillada > numCant_reserva) {
            console.log('ERROR, valCantPillada excesiva');
            const cantAlCarroControl = this.formRec.get('cant_alCarro');
            cantAlCarroControl?.setErrors({ cantidadInvalida: true });
          } else {
            // Actualiza el registro actual con la cantidad recogida
            this.registroActual.cant_recogida = numCant_recogida + valCantPillada;
            this.registroActual.stockUbi = numStockUbi - valCantPillada;
            this.registroActual.cant_alCarro = valCantPillada;

            // Cambia el estado del registro a "preparación empezada"
            this.registroActual.estado = Const.ESTADO_PREPARACION_UIC_EMPEZADA;

            // Espera que la actualización del registro se complete antes de continuar
            await this.updateRec();

            // Limpia el campo EAN del formulario después de la actualización
            this.formRec.patchValue({ ean: '' });

            // Si la cantidad recogida es igual a la cantidad de reserva, marca el registro como completado
            if (this.registroActual.cant_recogida === numCant_reserva) {
              this.registroActual.estado = Const.ESTADO_PREPARACION_UIC_COMPLETADA;

              // Actualiza nuevamente el registro para marcarlo como completado
              await this.updateRec();

              // Llama a la función avanzar para proceder con el siguiente paso
              this.avanzar();
            }
          }
        }
      }
    });



    // this.formRec?.get('ean')?.valueChanges.subscribe(async (valEan) => {
    //   if (valEan === this.registroActual.ean) {
    //     // Sumar cantidad recogida cant_alCarro 
    //     await this.pausar(400); // Pausa durante 0.2 segundos       
    //     const valCantPillada = parseInt(this.formRec.get('cant_alCarro')?.value);

    //     if (valCantPillada) {
    //       const numCant_recogida = parseInt(this.registroActual.cant_recogida)
    //       const numCant_reserva = parseInt(this.registroActual.cant_reserva)
    //       const numStockUbi = parseInt(this.registroActual.stockUbi)


    //       // Validar cantidad recogida
    //       if (numCant_recogida + valCantPillada > numCant_reserva) {
    //         console.log('ERROR, valCantPillada excesiva')
    //         const cantAlCarroControl = this.formRec.get('cant_alCarro');
    //         cantAlCarroControl?.setErrors({ cantidadInvalida: true });

    //       } else {

    //         // Actualizar registro Actual
    //         this.registroActual.cant_recogida = numCant_recogida + valCantPillada;
    //         this.registroActual.stockUbi = numStockUbi - valCantPillada;
    //         this.registroActual.cant_alCarro = valCantPillada;

    //         // update registro
    //         this.registroActual.estado = Const.ESTADO_PREPARACION_UIC_EMPEZADA;
    //         this.updateRec();

    //         // Limpiar campo ean del form
    //         this.formRec.patchValue({ ean: '' });

    //         // Si se han pillado todos los necesarios, grabar datos y a por el siguiente
    //         if (this.registroActual.cant_recogida == parseInt(this.registroActual.cant_reserva)) {
    //           this.registroActual.estado = Const.ESTADO_PREPARACION_UIC_COMPLETADA;
    //           this.updateRec();
    //           this.avanzar();
    //         } 



    //       }


    //     }

    //   }
    // });




  }




  cargaPreparacionList() {
    this.loading = true;

    const data = {
      recogida_cod: this.recogida_cod || null,
      todo: '0'
    };

    this.prepracionSrv.listRecogidaCod(data)
      .subscribe(resp => {
        this.loading = false;
        console.log('__cargaPreparacionList() listaPreparaciones', resp);
        if (resp.error) this.notificacionesSrv.aviso('error', resp.mensaje);
        this.preparacionesList = resp.data;
        this.totalUbis = resp.totales.ubis;

        if (!this.totalUbis) {
          this.notificacionesSrv.aviso('error', `No se han encontrado recogidas para el código ${this.recogida_cod}`);
          this.router.navigate(['./pda']);
          return
        }

        //   this.formRec.patchValue({ ubicacion: null });
        this.cargarRegistro(0);

      })
  }

  enviar() {

    this.enviado = true;

    if (this.formRec.invalid) {
      console.log("ERRORES:", this.formRec);
      return;
    }
    // Todo ok, enviar al BackEnd
    console.log('enviar() formRec.value', this.formRec.value);
  }



  // Modificar registro (estado y cantidad recogida), Versión 2 async await para evitar errores en los ordenes de los updates
  updateRec(): Promise<void> {
    return new Promise((resolve, reject) => {
      console.log('__updateRec registroActual', this.registroActual);

      this.prepracionSrv.updatePrep(this.registroActual)
        .subscribe(
          resp => {
            console.log('__updateRec resp', resp);
            if (resp.error) {
              this.notificacionesSrv.aviso('error', resp.mensaje);

              // Dejar los contadores como estaban
              this.registroActual.cant_recogida = this.registroActual.cant_recogida - this.registroActual.cant_alCarro;
              this.registroActual.stockUbi = this.registroActual.stockUbi + this.registroActual.cant_alCarro;
              this.registroActual.cant_alCarro = 1;

              reject(resp.mensaje); // Rechazar la promesa en caso de error
            } else {
              resolve(); // Resolver la promesa si todo está bien
              this.updateUicCliente() // forzar la asginación del UIC al cliente
            }
          },
          error => reject(error) // En caso de error en la llamada HTTP
        );
    });
  }



  cantidadValida(control: { value: any; }) {
    // Validar la cantidad recogida
    const valor = control.value;
    const cantReserva = this.registroActual.cant_reserva;

    if (isNaN(valor) || valor < 1 || valor > cantReserva) {
      return { cantidadInvalida: true };
    }

    return null;
  }



  //// AUX  ///



  get f() {
    return this.formRec.controls;
  }

  cargarRegistro(index: number): void {
    // Carga el registro actual según el índice proporcionado 
    if (index >= 0 && index < this.preparacionesList.length) {
      this.registroActual = this.preparacionesList[index];
      this.registroActual.uic = null // Obligar a pedir el carro
      this.currentIndex = index;
    }

    console.log('__cargarRegistro() registroActual', this.registroActual);
    console.log('__cargarRegistro() this.preparacionesList', this.preparacionesList);
  }


  async onUicEnter(event: any) {
    // ENTER en el input uci

    if (event.key === 'Enter') {
      event.preventDefault(); // Evita el comportamiento predeterminado del Enter (submit del formulario)
      this.enviado = true;

      // Validar el formato del UIC
      const uicControl = this.formRec.get('uic')?.value.toUpperCase();
      this.formRec.patchValue({ uic: uicControl });
      const uicRegex = /^UIC\d{10}$/;  // 'UIC' seguido de 10 dígitos
      console.log('onUicEnter', uicControl);
      console.log('__formRec', this.formRec);

      if (!uicRegex.test(uicControl)) {
        this.formRec.get('uic')?.setErrors({ formatoIncorrecto: true });
        return;
      }

      // Validar UIC
      const data = {
        uic: uicControl,
        preparacion: this.registroActual
      }
      this.uicSrv.valUicPreparacion(data)
        .subscribe(async resp => {
          console.log('__valUicPreparacion resp', resp);
          if (resp.error) {
            this.notificacionesSrv.aviso('error', resp.mensaje);
            this.formRec.get('uic')?.setErrors({ apiError: resp.mensaje });
            return;
          }

          // validar estado
          if (resp.data.estado === Const.ESTADO_UIC_CADUCADO) {
            this.formRec.get('uic')?.setErrors({ apiError: "Este UIC está marcado como CADUCADO" });
            return;
          }
          if (resp.data.estado === Const.ESTADO_UIC_EN_PREPARACION) {
            this.formRec.get('uic')?.setErrors({ apiError: "Este UIC está marcado como en PREPARACIÓN" });
            return;
          }
          if (resp.data.estado === Const.ESTADO_UIC_LLENO) {
            this.formRec.get('uic')?.setErrors({ apiError: "Este UIC está marcado como CARRO LLENO" });
            return;
          }


          // validar camp_cod
          if (resp.data.camp_cod && resp.data.camp_cod != this.registroActual.camp_cod) {
            this.formRec.get('uic')?.setErrors({ apiError: "Este UIC se está utilizando en la campaña " + resp.data.camp_cod });
            return;
          }

          // validar cliente
          if (resp.data.id_cliente) {
            if (this.registroActual.id_cliente !== resp.data.id_cliente) {
              this.formRec.get('uic')?.setErrors({ apiError: `Este UIC está asignado a ${resp.data.cliNombre}` });
              return;
            }
          }


          // Todo ok, Añadir el id_uic al registro
          this.registroActual.id_uic = resp.data.id;
          this.notificacionesSrv.aviso('success', resp.mensaje);


          // Formato correcto
          this.registroActual.uic = uicControl;
          await this.pausar(500); // Pausa 1/2 segundo

          // pasa el foco al siguiente campo
          const elementoSiguiente = document.getElementById('ubicacion');
          console.log('elementoSiguiente ', elementoSiguiente)
          if (elementoSiguiente) {
            elementoSiguiente.focus();
          }
        });
    }
  }





  async onUbicacionEnter(event: any) {
    // ENTER en el input ubicacion

    if (event.key === 'Enter') {
      event.preventDefault(); // Evita el comportamiento predeterminado del Enter (submit del formulario)

      // Validar ubicacion
      const valUbicacion = this.formRec.get('ubicacion')?.value;
      console.log('onUbicacionEnter', valUbicacion);

      if (valUbicacion !== this.registroActual.ubicacion) {
        this.enviado = true;
        this.formRec.get('ubicacion')?.setErrors({ noValido: true });
        return;
      }

      // valor correcto 
      await this.pausar(400); // Pausa durante 0.2 segundos
      return;

    }
  }

  saltaUic(inputElement: HTMLInputElement): void {
    // Disparar el evento "Enter" en el input 
    const enterEvent = new KeyboardEvent('keydown', {
      key: 'Enter',
      code: 'Enter',
      keyCode: 13,
      bubbles: true
    });

    // Disparar el evento "Enter" en el input
    inputElement.dispatchEvent(enterEvent);
  }



  saltaUbicacion() {
    // Acepta la ubucación propuesta
    Swal.fire({
      title: 'Aceptar ubicación',
      icon: 'question',
      html: `¿Estás seguro de aceptar la ubicación sin leerla?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {
        this.formRec.patchValue({
          ubicacion: this.registroActual.ubicacion
        })
      }
    })
  }


  saltaEan() {
    // Acepta el EAN propuesto
    Swal.fire({
      title: 'Aceptar EAN',
      icon: 'question',
      html: `¿Estás seguro de aceptar el código EAN del artículo sin leerlo?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {
        this.formRec.patchValue({
          ean: this.registroActual.ean
        })
      }
    })
  }


  carroLlenoClick() {
    // Duplicar registro con un nuevo UIC
    this.prepracionSrv.carroLleno(this.registroActual.id)
      .subscribe(async resp => {
        console.log('__carroLlenoClick resp', resp);
        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje);
          return;
        }
        this.notificacionesSrv.aviso('success', resp.mensaje);
        window.location.reload();




        //   await this.pausar(500); // Pausa durante 0.5 segundos 

        // Cargar el nuevo array
        //  this.cargaPreparacionList();
        // this.registroActual.uic = '';
        //  this.registroActual.cant_recogida = 0;
        //  this.uic = null;

        //   this.formRec.patchValue({
        //     cant_recogida: this.registroActual.cant_recogida,
        //     cant_alCarro: 1,
        //  / uic: this.uic,
        //     ean: '',
        //     estado: this.registroActual.estado,
        //     foto: this.registroActual.foto,
        //     id_articulo: this.registroActual.id_articulo,
        //     sku: this.registroActual.sku,
        //     ubicacion: ''
        //   })
      })

  }



  saltarArticuloClick() {
    this.registroActual.saltar = this.formRec.get('saltar')?.value;

    console.log('__saltarArticuloClick()')
    console.log('currentIndex ', this.currentIndex)
    console.log('registroActual ', this.registroActual)

    this.prepracionSrv.saltarArticulo(this.registroActual)
      .subscribe(async resp => {
        console.log('__saltarArticuloClick resp', resp);
        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje);
          return;
        }

        // Filtrar motivos definitivos en preparacionesList
        // this.filtraMotivos();

        // Avanzar al siguiente 
        if (this.currentIndex >= this.preparacionesList.length - 1) {
          this.currentIndex = -1;// volver al primero?
        }
        this.avanzar();
      });


  }





  async avanzar(): Promise<void> {
    // Avanza al siguiente registro
    this.uic = this.registroActual.uic;

    console.log('__avanzar()')

    // Quedan preparaciones pendientes?
    if (this.currentIndex >= this.preparacionesList.length - 1) {
      this.notificacionesSrv.aviso('success', `Preparación lista, ${this.preparacionesList.length} ubicaciones recogidas`);
      //  this.router.navigate(['./pda']);
      this.router.navigate([`./pda/prepara_detall/${this.recogida_cod}`]);
      return
    }

    this.currentIndex++;
    this.cargarRegistro(this.currentIndex);

    this.formRec.patchValue({
      cant_recogida: this.registroActual.cant_recogida,
      cant_alCarro: 1,
      uic: this.uic,
      ean: '',
      estado: this.registroActual.estado,
      foto: this.registroActual.foto,
      id_articulo: this.registroActual.id_articulo,
      sku: this.registroActual.sku,
      ubicacion: '',
      saltar: this.registroActual.saltar
    })

    // pasa el foco al siguiente campo

    await this.pausar(400); // Pausa durante 0.2 segundos 
    const elementoSiguiente = document.getElementById('ubicacion');
    console.log('elementoSiguiente ', elementoSiguiente)
    if (elementoSiguiente) {
      elementoSiguiente.focus();
    }

    console.log('__avanzar registroActual', this.registroActual);
  }



  buscaUbicacionAlternativa() {
    // Buscar este artículo en diferente ubicación

    Swal.fire({
      title: 'Ubicación alternativa',
      icon: 'question',
      html: `¿Quieres buscar este atrículo en otra ubicación?`,
      input: 'select',
      inputOptions: {
        'Nueva ubicación motivo1': 'Motivo UNO',
        'Nueva ubicación motivo2': 'Motivo DOS',
        'Nueva ubicación motivo3': 'Motivo TRES'
      },
      inputPlaceholder: 'Selecciona motivo...',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si',
    }).then((motivo) => {
      if (motivo.isConfirmed) {
        // Llamar al servicio para buscar nueva ubicación con el motivo
        this.registroActual.saltar = motivo.value;
        this.loading = true;
        this.prepracionSrv.buscaUbiAlternativa(this.registroActual)
          .subscribe(async resp => {
            this.loading = false;
            console.log('__buscaUbicacionAlternativa()', resp);

            if (resp.error) {
              this.notificacionesSrv.aviso('error', resp.mensaje);
              return;
            }
            if (resp.totales && resp.totales.salidaTipo === 'B') {
              this.notificacionesSrv.aviso('warning', resp.mensaje);
            }
            if (resp.totales && resp.totales.salidaTipo === 'C') {
              this.notificacionesSrv.aviso('success', resp.mensaje);
            }

            // Cargar las nuevas preparaciones
            this.cargaPreparacionList();
            await this.pausar(400); // Pausa durante 0.2 segundos
            this.cargarRegistro(0);

            // conservar uic en el form            
            await this.pausar(400); // Pausa durante 0.2 segundos
            const fakeEvent = { key: 'Enter', preventDefault: () => { } };  // Simula un objeto de evento Enter
            this.onUicEnter(fakeEvent);

          });

      }
    })
  }


  cerrarPreparacion() {
    // Marcar los que faltan como estado '8'
    Swal.fire({
      title: 'Cerrar recogida NO acabada',
      icon: 'warning',
      html: `¿Quieres cerrar esta recogida?`,
      input: 'select',
      inputOptions: {
        'Cerrar preparación motivo 1': 'Motivo UNO',
        'Cerrar preparación motivo 2': 'Motivo DOS',
        'Cerrar preparación motivo 3': 'Motivo TRES'
      },
      inputPlaceholder: 'Selecciona motivo...',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si',
    }).then((motivo) => {
      if (motivo.isConfirmed) {
        console.log('__cerrarPreparacion motivo: ', motivo)

        // Llamar al servicio para cerrar preparación con el motivo
        this.registroActual.saltar = motivo.value;
        this.loading = true;
        this.prepracionSrv.cerrarPreparacion(this.registroActual)
          .subscribe(resp => {
            this.loading = false;
            console.log('__cerrarPreparacion() resp', resp);
            if (resp.error) {
              this.notificacionesSrv.aviso('error', resp.mensaje);
              return;
            }

            this.notificacionesSrv.aviso('success', resp.mensaje);
            this.router.navigate([`./pda/prepara_detall/${this.recogida_cod}`]);
            return
          });
      }
    })

  }


  /// AUX ///

  filtraMotivos() {
    // Filtrar los motivos definitivos en preparacionesList
    this.preparacionesList = this.preparacionesList.
      filter(item => item.saltarDefinitivo !== '1'
      );
  }

  limpiaUic() {
    // Limpia el campo uic del formulario  
    this.formRec.get('uic')?.setValue('');
  }


  pausar(milisegundos: number): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(resolve, milisegundos);
    });
  }


  volverClick() {
    this.router.navigate(['./pda']);
    return
  }

  // Asigna un UIC al cliente
  updateUicCliente() {
    this.uicSrv.updateUicCliente(this.registroActual)
      .subscribe(resp => {
        console.log('__asignarClinete resp', resp);

        return;
      });
  }

}
