<div class=" animated fadeIn fast">

    <div class="row">


        <!-- Número de filas en consultas de datos -->
        <div class="col-md-6 col-xs-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Número de filas por defecto en las listas de datos</h4>
                    <div class="form-group">
                        <div class="col-6">
                            <select class="form-control form-control-sm"
                                    name="rowsLimitStr"
                                    [(ngModel)]="rowsLimitStr"
                                    (change)="rowsLimitChange()">
                                <option [value]="10">10</option>
                                <option [value]="50">50</option>
                                <option [value]="100">100</option>
                                <option [value]="999999">Todo</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Posición de la línea de totales -->
        <div class="col-md-6 col-xs-12  ">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Posición de los totales en las listas de datos</h4>
                    <div class="demo-radio-button">
                        <input type="radio"
                               id="radio_1"
                               [(ngModel)]="summaryPosition"
                               (ngModelChange)="cambiaSummaryPosition()"
                               value="top">
                        <label for="radio_1">Arriba</label>
                        <input type="radio"
                               id="radio_2"
                               [(ngModel)]="summaryPosition"
                               (ngModelChange)="cambiaSummaryPosition()"
                               value="bottom">
                        <label for="radio_2">Abajo</label>
                    </div>
                </div>
            </div>
        </div>



        <!-- Estado del menú -->
        <div class="col-md-6 col-xs-12  ">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Estado del menú al iniciar</h4>
                    <div class="demo-checkbox">
                        <input id="chkMenu"
                               type="checkbox"
                               class="filled-in"
                               [(ngModel)]="menuExtendido"
                               (ngModelChange)="cambiaMenu()" />
                        <label for="chkMenu">Expandido</label>
                    </div>
                </div>
            </div>
        </div>


        <!-- Usar imágenes optimizadas -->
        <div class="col-md-6 col-xs-12 ">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Mostrar imágenes optimizadas en las listas de datos</h4>
                    <div class="demo-radio-button">
                        <input type="radio"
                               id="radio_img_1"
                               name="radio_img"
                               [(ngModel)]="imgResized"
                               (ngModelChange)="cambiaImgResized()"
                               value="/">
                        <label for="radio_img_1">Usar las originales</label>
                        <input type="radio"
                               id="radio_img_2"
                               name="radio_img"
                               [(ngModel)]="imgResized"
                               (ngModelChange)="cambiaImgResized()"
                               value="/resize/">
                        <label for="radio_img_2">Usar fotos optimizadas</label>
                    </div>
                </div>
            </div>
        </div>


        <!-- colores -->
        <div class="col-md-6 col-xs-12 ">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Selecciona esquema de colores</h4>

                    <div class="r-panel-body">
                        <ul id="themecolors"
                            class="m-t-20">
                            <li><b>Opciones de Color</b></li>
                            <li>
                                <a (click)="cambiaColorTema('default')"
                                   data-theme="default"
                                   class="selector default-theme ">1</a>
                            </li>
                            <li>
                                <a (click)="cambiaColorTema('green')"
                                   data-theme="green"
                                   class="selector green-theme">2</a>
                            </li>
                            <li>
                                <a (click)="cambiaColorTema('red')"
                                   data-theme="red"
                                   class="selector red-theme">3</a>
                            </li>
                            <li><a (click)="cambiaColorTema('blue')"
                                   data-theme="blue"
                                   class="selector blue-theme">4</a>
                            </li>
                            <li>
                                <a (click)="cambiaColorTema('purple')"
                                   data-theme="purple"
                                   class="selector purple-theme">5</a>
                            </li>
                            <li>
                                <a (click)="cambiaColorTema('megna')"
                                   data-theme="megna"
                                   class="selector megna-theme">6</a>
                            </li>

                            <li class="d-block m-t-30"><b>Menú oscuro</b></li>
                            <li>
                                <a (click)="cambiaColorTema('default-dark')"
                                   data-theme="default-dark"
                                   class="selector default-dark-theme">7</a>
                            </li>
                            <!-- <li><a (click)="cambiaColorTema('green-dark')" data-theme="green-dark" class="selector green-dark-theme">8</a></li>
                        <li><a (click)="cambiaColorTema('red-dark')" data-theme="red-dark" class="selector red-dark-theme">9</a></li>
                        <li><a (click)="cambiaColorTema('blue-dark')" data-theme="blue-dark" class="selector blue-dark-theme">10</a></li>
                        <li><a (click)="cambiaColorTema('purple-dark')" data-theme="purple-dark" class="selector purple-dark-theme">11</a></li>
                        <li><a (click)="cambiaColorTema('megna-dark')" data-theme="megna-dark" class="selector megna-dark-theme">12</a></li> -->

                        </ul>

                    </div>

                </div>
            </div>
        </div>





        <!-- Voces -->
        <div class="col-md-6 col-xs-12 ">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Usar instrucciones por voz</h4>

                    <div class="demo-checkbox">
                        <input id="vozError"
                               type="checkbox"
                               class="filled-in"
                               [(ngModel)]="vozError"
                               (ngModelChange)="cambiaVozError()" />
                        <label for="vozError">Al mostrar un mensaje de <span class="text-danger">ERROR</span></label>
                    </div>

                    <div class="demo-checkbox">
                        <input id="vozSuccess"
                               type="checkbox"
                               class="filled-in"
                               [(ngModel)]="vozSuccess"
                               (ngModelChange)="cambiaVozSuccess()" />
                        <label for="vozSuccess">Al mostrar un mensaje de <span class="text-success">PROCESO EXITOSO</span></label>
                    </div>


                    <div class="demo-checkbox">
                        <input id="vozInfo"
                               type="checkbox"
                               class="filled-in"
                               [(ngModel)]="vozInfo"
                               (ngModelChange)="cambiaVozInfo()" />
                        <label for="vozInfo">Al mostrar un mensaje de <span class="text-info">INFORMACIÓN</span></label>
                    </div>

                    <div class="demo-checkbox">
                        <input id="vozWarning"
                               type="checkbox"
                               class="filled-in"
                               [(ngModel)]="vozWarning"
                               (ngModelChange)="cambiaVozWarning()" />
                        <label for="vozWarning">Al mostrar un mensaje de <span class="text-warning">ADVERTENCIA</span></label>
                    </div>

                    <hr>

                    <div class="demo-checkbox">
                        <input id="vozCajetin"
                               type="checkbox"
                               class="filled-in"
                               [(ngModel)]="vozCajetin"
                               (ngModelChange)="cambiaVozCajetin()" />
                        <label for="vozCajetin">Al cambiar de cajetín en repartos</label>
                    </div>

                    <hr>

                    <div class="demo-checkbox">
                        <input id="vozPale"
                               type="checkbox"
                               class="filled-in"
                               [(ngModel)]="vozPale"
                               (ngModelChange)="cambiaVozPale()" />
                        <label for="vozPale">Al cambiar de palé en ubicar entradas</label>
                    </div>


                </div>
            </div>
        </div>





    </div>
</div>