<!-- Tabla de ubicaciones para este artículo -->

<div class="card">
    <div class="card-body">

        <div *ngIf="avisoVacio"
             class="alert alert-warning mt-12">
            <i class="ti-alert"></i> No encuentro ubicaciones con este artículo.
            <button type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"> <span aria-hidden="true">×</span> </button>
        </div>

        <script>
            var alertList = document.querySelectorAll('.alert');
            alertList.forEach(function (alert) {
                new bootstrap.Alert(alert)
            })
        </script>

        <span *ngIf="loading">
            <h4 class="card-title text-info">Buscando ubicaciones...</h4>
            <h6 class="card-subtitle"></h6>
        </span>

        <!-- datatable -->
        <div *ngIf="ubiList.length">

            <ngx-datatable class='custom pointer'
                           [rows]="ubiList"
                           [limit]="10"
                           [loadingIndicator]="loading"
                           [columnMode]="'force'"
                           [headerHeight]="50"
                           [footerHeight]="50"
                           [rowHeight]="'auto'"
                           [messages]="messages"
                           [reorderable]="true"
                           [selectionType]="SelectionType.single"
                           (select)="onRowUbiClick($event)">

                <!-- codigo -->
                <ngx-datatable-column prop='codigo'
                                      name="Ubicación">
                    <ng-template let-value="value"
                                 ngx-datatable-cell-template>
                        <div style="font-family: 'Verdana', monospace; letter-spacing: 0.6px;">
                            {{value | mask: '00.00.00.00.00.00'}}
                        </div>
                    </ng-template>
                </ngx-datatable-column>

                <!-- tipo.nombre -->
                <ngx-datatable-column prop="tipo.nombre"
                                      name="Tipo">
                </ngx-datatable-column>

                <!-- cantidad -->
                <ngx-datatable-column prop='cantidad'
                                      name="Cantidad"
                                      class="text-right mr-12">
                    <ng-template let-value="value"
                                 ngx-datatable-cell-template>
                        <div class="text-right mr-12"
                             style="font-family: 'Verdana', monospace; letter-spacing: 0.6px;">
                            {{value | number: '0.0-0':'es'}}
                        </div>
                    </ng-template>
                </ngx-datatable-column>

                <!-- lote -->
                <ngx-datatable-column prop="lote"
                                      name="Lote">
                </ngx-datatable-column>

            </ngx-datatable>

        </div>

    </div>
</div>