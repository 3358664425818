import { Pipe, PipeTransform } from '@angular/core';
import { formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale';

@Pipe({
  name: 'friendlyDdmmyy'
})
export class FriendlyDdmmyyPipe implements PipeTransform {

  transform(value: Date | string): string {
    const dateInMilliseconds = Date.parse(value.toString()); // convierte la cadena en un valor numérico en milisegundos
    return formatDistanceToNow(dateInMilliseconds, { locale: es }); // utiliza la biblioteca date-fns para mostrar la diferencia con la fecha actual
  }

}
