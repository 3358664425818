import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


/**
 * Valida el UIC de un envío
 */


@Component({
  selector: 'app-prepara-envio',
  templateUrl: './prepara-envio.component.html',
  styleUrls: ['./prepara-envio.component.scss']
})
export class PreparaEnvioComponent implements OnInit {

  uic: string = '';
  uicInput: string | null = null;
  uicError: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }


  ngOnInit(): void {
    // Suscribirse a los parámetros de la url
    this.activatedRoute.params.subscribe(({ uic }) => {
      this.uic = uic;
    })
  }


  //// AUX  ///
  validarLongitudUIC(valor: string) {
    if (valor.length === 13) {
      this.validarUic()
    }
  }

  validarUic() {
    if (this.uicInput) this.uicInput = this.uicInput.toUpperCase();

    if (this.uicInput !== this.uic) {
      this.uicError = true;
    } else {
      this.uicError = false;
      this.router.navigate([`/pda/prepara_envio2/${this.uic}`]);
    }
  }

}
