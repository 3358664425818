<!-- ============================================================== -->
<!-- Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->
<aside class="left-sidebar">
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar">
        <!-- Sidebar navigation-->
        <nav class="sidebar-nav">
            <ul id="sidebarnav">
                <li class="user-profile">
                    <a class="has-arrow waves-effect waves-dark"
                       href="#"
                       aria-expanded="false">
                        <img [src]="getUserFoto()"
                             alt="usurio" />
                        <span class="hide-menu">{{usuarioSrv.userdata.nombre}} </span>
                    </a>
                    <ul aria-expanded="false"
                        class="collapse">
                        <li><a routerLink="./usr_perfil"
                               routerLinkActive="active">Perfil de usuario</a></li>
                        <li><a href="javascript:void()">Mensajes</a></li>
                        <li><a routerLink="./config"
                               routerLinkActive="active">App config</a></li>
                        <li><a href="javascript:void()"
                               (click)="logOut()"
                               class="pointer">Desconectar</a></li>
                    </ul>
                </li>
                <li class="nav-devider"></li>
                <li class="nav-small-cap">SGA VIAPACK</li>

                <!-- menú de opciones -->
                <li *ngFor="let item of sidebarSrv.menu">
                    <a class="has-arrow waves-effect waves-dark"
                       href="#"
                       routerLinkActive="active-link"
                       [routerLinkActiveOptions]="{exact: true}"
                       aria-expanded="false">
                        <i [class]="item.icono"></i>
                        <span class="hide-menu">
                            {{item.titulo}}                          
                        </span>
                    </a>
                    <ul aria-expanded="false"
                        class="collapse">
                        <li *ngFor="let itemSub of item.submenu">
                            <a [routerLink]="itemSub.url"
                               routerLinkActive="active"
                               [routerLinkActiveOptions]={exact:true}>
                                {{itemSub.titulo}}
                            </a>

                        </li>
                    </ul>
                </li>

            </ul>
        </nav>
        <!-- End Sidebar navigation -->
    </div>
    <!-- End Sidebar scroll-->
</aside>
<!-- ============================================================== -->
<!-- End Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->