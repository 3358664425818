<div [class.oculto]="modalFactSalidaSrv.ocultarModal"
     class="fondo-modal-imagen ">

    <div class="modal fade show"
         id="exampleModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel1"
         style="display: block;"
         (click)="cerrarModalClickOutside($event)">

        <div class="modal-dialog modal-lg"
             role="document">

            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title"
                        id="exampleModalLabel1">
                        <i class="fa-solid fa-boxes-packing"></i> Embalaje en pedido {{modalFactSalidaSrv.pedido?.pedido_cli }}
                    </h4>
                    <button type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close">
                        <span (click)="cerrarModal()"
                              aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">

                    <div class="card card-body">

                        <form [formGroup]="factForm"
                              (ngSubmit)="enviar()"
                              class="form-horizontal">

                            <div class="row"> <!-- HORAS -->

                                <!-- fac_pack_horas -->
                                <div class="col-12"
                                     style="margin-bottom: -18px;margin-top: -18px;">
                                    <div class="form-group form-group-sm row align-items-center"
                                         [ngClass]="{ 'has-danger': enviado && f['fac_pack_horas'].errors }">
                                        <label class="col-8 col-form-label text-right">
                                            <i> Horas empleadas para embalar:</i>
                                        </label>

                                        <div class="col-4">
                                            <input type="text"
                                                   formControlName="fac_pack_horas"
                                                   class="form-control "
                                                   mask="separator.2"
                                                   suffix=" Horas"
                                                   [ngClass]="{ 'form-control-danger': enviado && f['fac_pack_horas'].errors }">
                                            <div *ngIf="enviado && f['fac_pack_horas'].errors"
                                                 class="form-control-feedback">
                                                <div *ngIf="f['fac_pack_horas'].errors?.['apiError']">{{factForm.get('fac_pack_horas')?.errors?.['apiError']}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div><!-- HORAS -->





                            <div class="line-with-text">
                                <h6>Bolsas</h6>
                                <hr>
                            </div>

                            <div class="row">

                                <!-- fac_pack_bolsa01 -->
                                <div class="col-4">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['fac_pack_bolsa01'].errors }">
                                        <label class="form-control-label">
                                            Pequeñas:
                                        </label>

                                        <input type="text"
                                               formControlName="fac_pack_bolsa01"
                                               class="form-control"
                                               mask="separator.0"
                                               suffix=" bolsas"
                                               [ngClass]="{ 'form-control-danger': enviado && f['fac_pack_bolsa01'].errors }">
                                        <div *ngIf="enviado && f['fac_pack_bolsa01'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['fac_pack_bolsa01'].errors?.['apiError']">{{factForm.get('fac_pack_bolsa01')?.errors?.['apiError']}}</div>
                                        </div>
                                    </div>
                                </div>



                                <!-- fac_pack_bolsa02 -->
                                <div class="col-4">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['fac_pack_bolsa02'].errors }">
                                        <label class="form-control-label">
                                            Medianas:
                                        </label>

                                        <input type="text"
                                               formControlName="fac_pack_bolsa02"
                                               class="form-control"
                                               mask="separator.0"
                                               suffix=" bolsas"
                                               [ngClass]="{ 'form-control-danger': enviado && f['fac_pack_bolsa02'].errors }">
                                        <div *ngIf="enviado && f['fac_pack_bolsa02'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['fac_pack_bolsa02'].errors?.['apiError']">{{factForm.get('fac_pack_bolsa02')?.errors?.['apiError']}}</div>
                                        </div>
                                    </div>
                                </div>

                                <!-- fac_pack_bolsa03 -->
                                <div class="col-4">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['fac_pack_bolsa03'].errors }">
                                        <label class="form-control-label">
                                            Grandes:
                                        </label>

                                        <input type="text"
                                               formControlName="fac_pack_bolsa03"
                                               class="form-control"
                                               mask="separator.0"
                                               suffix=" bolsas"
                                               [ngClass]="{ 'form-control-danger': enviado && f['fac_pack_bolsa03'].errors }">
                                        <div *ngIf="enviado && f['fac_pack_bolsa03'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['fac_pack_bolsa03'].errors?.['apiError']">{{factForm.get('fac_pack_bolsa03')?.errors?.['apiError']}}</div>
                                        </div>
                                    </div>
                                </div>

                            </div> <!-- row BOLSAS -->



                            <div class="line-with-text">
                                <h6>Cajas</h6>
                                <hr>
                            </div>


                            <div class="row"> <!-- CAJAS -->

                                <!-- fac_pack_caja01 -->
                                <div class="col-4">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['fac_pack_caja01'].errors }">
                                        <label class="form-control-label">
                                            Pequeñas:
                                        </label>

                                        <input type="text"
                                               formControlName="fac_pack_caja01"
                                               class="form-control"
                                               mask="separator.0"
                                               suffix=" cajas"
                                               [ngClass]="{ 'form-control-danger': enviado && f['fac_pack_caja01'].errors }">
                                        <div *ngIf="enviado && f['fac_pack_caja01'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['fac_pack_caja01'].errors?.['apiError']">{{factForm.get('fac_pack_caja01')?.errors?.['apiError']}}</div>
                                        </div>
                                    </div>
                                </div>



                                <!-- fac_pack_caja02 -->
                                <div class="col-4">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['fac_pack_caja02'].errors }">
                                        <label class="form-control-label">
                                            Medianas:
                                        </label>

                                        <input type="text"
                                               formControlName="fac_pack_caja02"
                                               class="form-control"
                                               mask="separator.0"
                                               suffix=" cajas"
                                               [ngClass]="{ 'form-control-danger': enviado && f['fac_pack_caja02'].errors }">
                                        <div *ngIf="enviado && f['fac_pack_caja02'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['fac_pack_caja02'].errors?.['apiError']">{{factForm.get('fac_pack_caja02')?.errors?.['apiError']}}</div>
                                        </div>
                                    </div>
                                </div>

                                <!-- fac_pack_caja03 -->
                                <div class="col-4">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['fac_pack_caja03'].errors }">
                                        <label class="form-control-label">
                                            Grandes:
                                        </label>

                                        <input type="text"
                                               formControlName="fac_pack_caja03"
                                               class="form-control"
                                               mask="separator.0"
                                               suffix=" cajas"
                                               [ngClass]="{ 'form-control-danger': enviado && f['fac_pack_caja03'].errors }">
                                        <div *ngIf="enviado && f['fac_pack_caja03'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['fac_pack_caja03'].errors?.['apiError']">{{factForm.get('fac_pack_caja03')?.errors?.['apiError']}}</div>
                                        </div>
                                    </div>
                                </div>


                            </div> <!-- row CAJAS -->



                            <div class="line-with-text">
                                <h6>Varios</h6>
                                <hr>
                            </div>

                            <div class="row"> <!-- VARIOS-->

                                <!-- fac_pack_burbuja -->
                                <div class="col-3">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['fac_pack_burbuja'].errors }">
                                        <label class="form-control-label">
                                            Burbuja:
                                        </label>

                                        <input type="text"
                                               formControlName="fac_pack_burbuja"
                                               class="form-control"
                                               mask="separator.0"
                                               [ngClass]="{ 'form-control-danger': enviado && f['fac_pack_burbuja'].errors }">
                                        <div *ngIf="enviado && f['fac_pack_burbuja'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['fac_pack_burbuja'].errors?.['apiError']">{{factForm.get('fac_pack_burbuja')?.errors?.['apiError']}}</div>
                                        </div>
                                    </div>
                                </div>

                                <!-- fac_pack_viruta -->
                                <div class="col-3">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['fac_pack_viruta'].errors }">
                                        <label class="form-control-label">
                                            Viruta:
                                        </label>

                                        <input type="text"
                                               formControlName="fac_pack_viruta"
                                               class="form-control"
                                               mask="separator.0"
                                               [ngClass]="{ 'form-control-danger': enviado && f['fac_pack_viruta'].errors }">
                                        <div *ngIf="enviado && f['fac_pack_viruta'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['fac_pack_viruta'].errors?.['apiError']">{{factForm.get('fac_pack_viruta')?.errors?.['apiError']}}</div>
                                        </div>
                                    </div>
                                </div>


                                <!-- fac_pack_papel -->
                                <div class="col-3">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['fac_pack_papel'].errors }">
                                        <label class="form-control-label">
                                            P.Kraft:
                                        </label>

                                        <input type="text"
                                               formControlName="fac_pack_papel"
                                               class="form-control"
                                               mask="separator.0"
                                               [ngClass]="{ 'form-control-danger': enviado && f['fac_pack_papel'].errors }">
                                        <div *ngIf="enviado && f['fac_pack_papel'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['fac_pack_papel'].errors?.['apiError']">{{factForm.get('fac_pack_papel')?.errors?.['apiError']}}</div>
                                        </div>
                                    </div>
                                </div>



                                <!-- fac_pack_otros -->
                                <div class="col-3">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['fac_pack_otros'].errors }">
                                        <label class="form-control-label">
                                            Otros:
                                        </label>

                                        <input type="text"
                                               formControlName="fac_pack_otros"
                                               class="form-control"
                                               mask="separator.0"
                                               [ngClass]="{ 'form-control-danger': enviado && f['fac_pack_otros'].errors }">
                                        <div *ngIf="enviado && f['fac_pack_otros'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['fac_pack_otros'].errors?.['apiError']">{{factForm.get('fac_pack_otros')?.errors?.['apiError']}}</div>
                                        </div>
                                    </div>
                                </div>


                            </div> <!-- VARIOS-->




                            <div class="line-with-text">
                                <h6>Palés</h6>
                                <hr>
                            </div>

                            <div class="row"> <!-- PALES-->

                                <!-- fac_pack_pale01 -->
                                <div class="col-4">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['fac_pack_pale01'].errors }">
                                        <label class="form-control-label">
                                            No retorno:
                                        </label>

                                        <input type="text"
                                               formControlName="fac_pack_pale01"
                                               class="form-control"
                                               mask="separator.0"
                                               suffix=" Palés"
                                               [ngClass]="{ 'form-control-danger': enviado && f['fac_pack_pale01'].errors }">
                                        <div *ngIf="enviado && f['fac_pack_pale01'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['fac_pack_pale01'].errors?.['apiError']">{{factForm.get('fac_pack_pale01')?.errors?.['apiError']}}</div>
                                        </div>
                                    </div>
                                </div>


                                <!-- fac_pack_pale02 -->
                                <div class="col-4">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['fac_pack_pale02'].errors }">
                                        <label class="form-control-label">
                                            Europeo:
                                        </label>

                                        <input type="text"
                                               formControlName="fac_pack_pale02"
                                               class="form-control"
                                               mask="separator.0"
                                               suffix=" Palés"
                                               [ngClass]="{ 'form-control-danger': enviado && f['fac_pack_pale02'].errors }">
                                        <div *ngIf="enviado && f['fac_pack_pale02'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['fac_pack_pale02'].errors?.['apiError']">{{factForm.get('fac_pack_pale02')?.errors?.['apiError']}}</div>
                                        </div>
                                    </div>
                                </div>


                                <!-- fac_pack_pale03 -->
                                <div class="col-4">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['fac_pack_pale03'].errors }">
                                        <label class="form-control-label">
                                            Americano:
                                        </label>

                                        <input type="text"
                                               formControlName="fac_pack_pale03"
                                               class="form-control"
                                               mask="separator.0"
                                               suffix=" Palés"
                                               [ngClass]="{ 'form-control-danger': enviado && f['fac_pack_pale03'].errors }">
                                        <div *ngIf="enviado && f['fac_pack_pale03'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['fac_pack_pale03'].errors?.['apiError']">{{factForm.get('fac_pack_pale03')?.errors?.['apiError']}}</div>
                                        </div>
                                    </div>
                                </div>


                                <!-- fac_pack_pale04 -->
                                <div class="col-4">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['fac_pack_pale04'].errors }">
                                        <label class="form-control-label">
                                            De plástico:
                                        </label>

                                        <input type="text"
                                               formControlName="fac_pack_pale04"
                                               class="form-control"
                                               mask="separator.0"
                                               suffix=" Palés"
                                               [ngClass]="{ 'form-control-danger': enviado && f['fac_pack_pale04'].errors }">
                                        <div *ngIf="enviado && f['fac_pack_pale04'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['fac_pack_pale04'].errors?.['apiError']">{{factForm.get('fac_pack_pale04')?.errors?.['apiError']}}</div>
                                        </div>
                                    </div>
                                </div>


                                <!-- fac_pack_pale05 -->
                                <div class="col-4">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['fac_pack_pale05'].errors }">
                                        <label class="form-control-label">
                                            Ignífugo:
                                        </label>

                                        <input type="text"
                                               formControlName="fac_pack_pale05"
                                               class="form-control"
                                               mask="separator.0"
                                               suffix=" Palés"
                                               [ngClass]="{ 'form-control-danger': enviado && f['fac_pack_pale05'].errors }">
                                        <div *ngIf="enviado && f['fac_pack_pale05'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['fac_pack_pale05'].errors?.['apiError']">{{factForm.get('fac_pack_pale05')?.errors?.['apiError']}}</div>
                                        </div>
                                    </div>
                                </div>

                            </div> <!-- PALES-->



                            <hr>

                            <div class="d-flex justify-content-between">

                                <button [disabled]="loading"
                                        type="button"
                                        (click)="cerrarModal()"
                                        class="btn waves-effect waves-light btn-rounded btn-secondary"
                                        data-dismiss="modal">Cancelar</button>

                                <button [disabled]="loading"
                                        type="submit"
                                        class="ml-2 btn waves-effect waves-light btn-rounded btn-success">
                                    <i class="fa fa-spinner fa-spin"
                                       *ngIf="loading"></i> Enviar</button>
                            </div>

                        </form>
                    </div>


                </div>
            </div>
        </div>
    </div>


</div>