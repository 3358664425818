<!-- Datos de la cabecera de la entrada-->

<div class="card border border-info rounded">
    <div class="card-body">

        <h4 class="card-title text-info">Almacen {{entrada.almCodigo}} - {{entrada.almNombre}}</h4>
        <h6 class="card-subtitle">Datos de la entrada</h6>
        <div class="row">

            <div class="col-6">
                <ul>
                    <li class="card-text"><b>Fecha:</b> {{entrada.fecha_entrada}}</li>
                    <li class="card-text"><b>Cliente:</b> {{entrada.id_cliente}}-{{entrada.cliNombre}} <small>({{entrada.cliPoblacion}})</small></li>
                    <li class="card-text"><b>Total palés:</b> {{entrada.pales}}</li>
                </ul>
            </div>
            <div class="col-6">
                <ul>
                    <li class="card-text"><b>Proveedor:</b> {{entrada.proveedor}}</li>
                    <li class="card-text"><b>Albarán:</b> {{entrada.albaran}}</li>
                </ul>
            </div>

        </div>

        <h6 class="card-subtitle mt-2">Creada por {{entrada.username}}
            <span *ngIf="entrada.created_at"> hace {{entrada.created_at! | friendlyDdmmyy}}</span>
        </h6>

    </div>
</div>