import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UsuarioService } from 'src/app/services/datos/usuario.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { FriendlyTimestampPipe } from 'src/app/pipes/friendly-timestamp.pipe';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loading: boolean = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    public usuarioSrv: UsuarioService,
    private notificacionesService: NotificacionesService
  ) { }

  public loginForm = this.fb.group({
    username: [localStorage.getItem('username') || '', [Validators.required]],
    password: ['', Validators.required],
    remember: [localStorage.getItem('recordar') === 'true' || false]
  })

  ngOnInit(): void {
  }

  enviar() {
    const frm = this.loginForm;
    this.loading = true;
    this.usuarioSrv.logIn(frm.value)
      .subscribe(respuesta => {
        this.loading = false;
        if (respuesta.error) {
          this.notificacionesService.aviso('error', respuesta.mensaje);
        } else {
          let mensajePiped: string = '';
          if (this.usuarioSrv.userdata.timestamp) {
            mensajePiped = '<br>Tu última conexión fue hace ' + new FriendlyTimestampPipe().transform(this.usuarioSrv.userdata.timestamp!);
          }
          this.notificacionesService.aviso('info', `Bienvenid@ ${respuesta.data.userdata.nombre!} ${mensajePiped}`);
          if (frm.get('remember')?.value) {
            localStorage.setItem('username', frm.get('username')!.value!);
            localStorage.setItem('recordar', 'true');
          } else {
            localStorage.removeItem('username');
            localStorage.removeItem('recordar');
          }

          if (this.usuarioSrv.userdata.rol == "usuario") {
            this.router.navigateByUrl('/pda');
          } else {
            this.router.navigateByUrl('/panel');
          }

          // ir a un landingPage en función de su rol
          switch (this.usuarioSrv.userdata.rol) {
            case 'usuario':
              this.router.navigateByUrl('/pda');
              break;
            case 'admin':
              this.router.navigateByUrl('/panel');
              break;
            default:
              this.notificacionesService.aviso('warning', `Esta Intranet no es accesible para tu rol de acceso como ${this.usuarioSrv.userdata.rol}.`);
              this.usuarioSrv.logOut();
              break;
          }

        }
      })
  }



}
