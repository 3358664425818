// componentes  módulos
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// servicios
import { UicService } from 'src/app/services/datos/uic.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { ClientesService } from 'src/app/services/datos/clientes.service';
import { UsuarioService } from 'src/app/services/datos/usuario.service';

// interfaces y modelos
import { ICliente } from 'src/app/interfaces/cliente';
import { IUicLabel } from 'src/app/interfaces/uicLabel';

@Component({
  selector: 'app-uic-labels',
  templateUrl: './uic-labels.component.html',
  styleUrls: ['./uic-labels.component.scss']
})
export class UicLabelsComponent implements OnInit { 

  public uicLabelList: IUicLabel[] = [];
  limiteRegistros: number = 10;
  totalRegistros: number = 99;

  public clientesList: ICliente[] = [];
  public id_cliente: string = '';
  public cantidad: number = 1;

  public enviado: boolean = false;
  public loading: boolean = false;

  public formLabel: FormGroup = new FormGroup({});

  constructor(
    private fb: FormBuilder,
    private notificacionesSrv: NotificacionesService,
    public uicSrv: UicService,
    public clientesSrv: ClientesService,
    private usuarioSrv: UsuarioService
  ) { }

  ngOnInit(): void {

    this.formLabel = this.fb.group({
      cantidad: [1, Validators.required],
      id_cliente: [''],
      id_usuario: [this.usuarioSrv.userdata.id]
    })

    this.getListaClientes();
    this.getListaUic();
  }

  getListaClientes() {
    // carga lista de Clientes
    this.loading = true;
    this.clientesSrv.getLista()
      .subscribe(respuesta => {
        this.loading = false;
        this.clientesList = respuesta.data
      })
  }

  getListaUic() {
    // carga lista de UIC's
    this.loading = true;
    this.uicSrv.getLista(this.limiteRegistros)
      .subscribe(respuesta => {
        console.log(respuesta)
        this.loading = false;
        this.uicLabelList = respuesta.data
        this.totalRegistros = respuesta.totales.totalRegistros 
      })
  }

  enviar() {
    this.enviado = true;

    console.table(' __enviar formLabel.value', this.formLabel.value);
    this.loading = true;

    this.uicSrv.new(this.formLabel.value)
      .subscribe(resp => {
        this.loading = false;
        console.log(resp);

        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje);
          for (let controlName in resp.mensaje) {
            this.formLabel.get(controlName)!.setErrors({ 'apiError': resp.mensaje[controlName] });
          }
        } else {
          this.notificacionesSrv.aviso('success', resp.mensaje);
          this.totalRegistros = resp.totales.totalRegistros;
          this.getListaUic();
        }
      })
  }

  onClienteSelect(id: string) {
    this.id_cliente = id;
  }

  //// Aux  ///


  get f() {
    return this.formLabel.controls;
  }

  cargaMas() {
    this.limiteRegistros += 30;
    if (this.limiteRegistros > this.totalRegistros) this.limiteRegistros = this.totalRegistros;
    this.getListaUic();
  } 

}
