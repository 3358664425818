import { Pipe, PipeTransform } from '@angular/core';
/**
 * Si es '1' pone un icon check
 */
@Pipe({
  name: 'iconCheck'
})

export class iconCheckPipe implements PipeTransform {
  transform(value: any): string {
    return value === '1' ? '<i class="text-success fa-solid fa-check"></i>' : '';
  }
}