import { EventEmitter, Injectable } from '@angular/core';
import { Subject, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IApiResp } from '../interfaces/api-resp';
import { HttpClient } from '@angular/common/http';
import { UsuarioService } from './datos/usuario.service';
import { IPedido } from '../interfaces/pedido';



@Injectable({
  providedIn: 'root'
})
export class ModalFactSalidaService {
  apiURL: string = environment.apiURL;

  private _ocultarModal: boolean = true;
  private _pedido: any;

  pedidoSubject: Subject<IPedido> = new Subject<IPedido>();
  modalClosed: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private http: HttpClient,
    private usuarioSrv: UsuarioService,
  ) { }


  get pedido(): any {
    return this._pedido;
  }

  set pedido(value: any) {
    this._pedido = value;
    this.pedidoSubject.next(value);
  }

  get ocultarModal() {
    return this._ocultarModal;
  }


  updateEmbalajes(pedido: IPedido) {
    const path = `${this.apiURL}/pedidos/updateEmbalajes/${pedido.id}`;

    console.log('__updateEmbalajes', pedido);

    return this.http.post<IApiResp>(path, pedido)
      .pipe(
        catchError(error => {
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }



  abrirModal() {
    this._ocultarModal = false;
    console.log('__abrirModal()', this.pedido)
  }

  cerrarModal() {
    // cerrar modal y emitir 'modalClosed' para que el subscriptor se entere
    this._ocultarModal = true;
    console.log('__cerrarModal()', this.pedido)
    this.modalClosed.emit();

  }

  ///// AUX ////

  errorAuth() {
    this.usuarioSrv.logOut();
    console.log('error', environment.apiErrorAuth);
    // this.notificacionesSrv.aviso('error', environment.apiErrorAuth);
  }

}
