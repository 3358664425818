import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

// interfaces
import { IReparto } from 'src/app/interfaces/reparto';

// servicios
import { RepartosService } from 'src/app/services/datos/repartos.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { ICliente } from 'src/app/interfaces/cliente';
import { UsuarioService } from 'src/app/services/datos/usuario.service';

// librerías
import Swal from 'sweetalert2';


/**
 * Presenta la lista de artículos de un carrito en cajetínes de evíos de un envío
 */


@Component({
  selector: 'app-prepara-envio2',
  templateUrl: './prepara-envio2.component.html',
  styleUrls: ['./prepara-envio2.component.scss']
})
export class PreparaEnvio2Component implements OnInit {

  @ViewChild('eanInputField') eanInputElement!: ElementRef;

  ngAfterViewInit() {
    // Después de que la vista esté inicializada, foco el campo
    this.eanInputElement.nativeElement.focus();
  }

  

  uic: string = '';
  eanInput: string | null = null;
  eanError: boolean = false;

  public eanList: any[] = [];
  public eanListAgrupados: any[] = [];
  public totales: any = {};

  totalArticulos: number = 0;
  loading: boolean = false;

  public cliente: ICliente = {};

  public fotoDir: string = environment.fotoDir;
  public imgResized: string = localStorage.getItem('imgResized') || '/resize/';


  constructor(
    private usrSrv: UsuarioService,
    private RepartoSrv: RepartosService,
    private notificacionesSrv: NotificacionesService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }


  ngOnInit(): void {
    // Suscribirse a los parámetros de la url
    this.activatedRoute.params.subscribe(({ uic }) => {
      this.uic = uic;
      this.cargaEanList();
    })
  }

  validarEAN() {
    // Valida si existe el EAN del input eanInput
    const articuloEncontrado = this.eanListAgrupados.find(item => item.articulo.ean === this.eanInput);

    if (articuloEncontrado) {
      console.log('EAN encontrado en artículo:', articuloEncontrado);

      this.router.navigate([`/pda/prepara_envio3/${this.uic}/${articuloEncontrado.articulo.id}`]);
    } else {
      this.eanError = true;
      console.log('EAN no encontrado en este UIC');
    }
  }


  cargaEanList() {
    this.loading = true;
    this.RepartoSrv.getRepartosListUic(this.uic)
      .subscribe(resp => {
        this.loading = false;
        console.log('__cargaEanList() listaPreparaciones', resp);
        if (resp.error) {
          // this.notificacionesSrv.aviso('error', resp.mensaje);
          console.log('__cargaEanList() resp.error',resp.error)
          console.log('__cargaEanList() resp.mensaje',resp.mensaje)
          this.router.navigate(['./pda']);
          return
        }
        this.eanList = resp.data;
        this.totales = resp.totales;

        this.eanListAgrupados = this.totales.eanListAgrupados;
        this.totalArticulos = this.totales.articulos;
        this.cliente = this.totales.cliente;

        console.log('cliente', this.cliente)

        console.log('this.totalArticulos', this.totalArticulos)
        if (!this.totalArticulos) {
          this.notificacionesSrv.aviso('error', `No se han encontrado artículos para este UIC ${this.uic}`);
          this.router.navigate(['./pda']);
          return
        }

      })
  }



  articClick(artic: any) {
    // Click sobre un artículo de la lista
    console.log('__articClick()', artic);
    this.eanInput = artic.articulo.ean;
    this.router.navigate([`/pda/prepara_envio3/${this.uic}/${artic.articulo.id}`]);
  }

  ///////////   AUX   /////////////

  
  pdfEtiquetaMulti() {
    Swal.fire({
      title: 'Imprimir etiquetas',
      icon: 'question',
      html: `¿Quieres imprimir las etiquetas para todas las cajas '1' de los cajetínes no completados de este carro?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          lista: this.eanList,
          id_usuario: this.usrSrv.userdata.id
        };

        //console.log('__pdfEtiquetaMulti() data', data);
        this.loading = true;
        this.RepartoSrv.pdfEtiquetaMulti(data)
          .subscribe(resp => {
            this.loading = false;
            //console.log('__pdfEtiquetaMulti()', resp);
            if (resp.error) {
              this.notificacionesSrv.aviso('error', resp.mensaje);
              return
            }
            this.notificacionesSrv.aviso('success', resp.mensaje);
          });

      }
    })
  }




  limitarYAgregarPuntos(value: string, maxLength: number): string {
    // Acorta un string a maxLength caracteres
    if (value.length > maxLength) {
      return value.substring(0, maxLength) + '...';
    } else {
      return value;
    }
  }




}
