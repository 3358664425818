<div id="main-wrapper">
    <app-cabecera></app-cabecera>

    <div class="page-wrapper"
         style=" margin-left: 0px;">

        <div class="container-fluid">            

            <!-- ============================================================== -->
            <!-- Start Page Content -->
            <!-- ============================================================== -->

            <router-outlet></router-outlet>

            <!-- ============================================================== -->
            <!-- End Page Content -->
            <!-- ============================================================== -->

        </div>
    </div>

</div>