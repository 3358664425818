import { RutasHijasModule } from './rutas-hijas.routing';

// Módulos
import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"

// Guards
import { AuthGuard } from '../guards/auth.guard';

// Componentes
import { PagesComponent } from './pages.component';
import { AdminGuard } from '../guards/admin.guard';
import { PagesPdaComponent } from '../pages-pda/pages-pda.component';
import { PanelComponent } from './panel/panel.component';


/**
 * Rutas hijas para componentes logeado 
 */

const routes: Routes = [
    {
        // PagesComponent.html tiene la plantilla e incluye un <router-outlet> para el menú hijo
        // Las rutas-hijas, se cargan vía lazzyLoad desde el módulo rutas-hijas.module.ts  canLoad: [AuthGuard]

        // Solo pueden acceder: [AuthGuard]=usuarios loguineados / [AdminGuard]=usuarios rol='admin' 

        path: 'panel', component: PagesComponent,
        canActivate: [AuthGuard, AdminGuard],
        canLoad: [AuthGuard],
        loadChildren: () => import('./rutas-hijas.routing').then(m => m.RutasHijasModule)
    } 
];

@NgModule({
    imports: [RouterModule.forChild(routes)],      // Menú forChild
    exports: [RouterModule]
})

export class PagesRoutingModule { }
