import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SelectionType } from '@swimlane/ngx-datatable';

// interfaces
import { IEntrada, IEntradaDetall } from 'src/app/interfaces/entrada';
import { IUbicacion } from 'src/app/interfaces/ubicacion';

// servicios
import { UbicacionesService } from 'src/app/services/datos/ubicaciones.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';

@Component({
  selector: 'app-ubicaciones-articulo',
  templateUrl: './ubicaciones-articulo.component.html',
  styleUrls: ['./ubicaciones-articulo.component.scss']
})
export class UbicacionesArticuloComponent implements OnInit, OnChanges {

  @Input() entrada: IEntrada = {};
  @Input() detall: IEntradaDetall = {};
  @Input() padre: any;                  // componente padre


  public ubiList: IUbicacion[] = [];

  SelectionType = SelectionType;
  selected = [];

  public loading: boolean = false;
  public avisoVacio: boolean = false;

  messages = {
    emptyMessage: `<span class="text-danger">Sin datos...</span>`,
    totalMessage: 'Total',
    selectedMessage: ''
  }

  constructor(
    private ubicacionesSrv: UbicacionesService,
    private notificacionesService: NotificacionesService) { }

  ngOnInit(): void {
    this.buscaUbis();
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['entrada']) {
      this.buscaUbis();
    }
  }


  buscaUbis() {
    // lista de ubicaciones con este artículo 
    if (!this.detall.id_articulo) return;

    this.loading = true;
    this.avisoVacio = false;

    this.ubicacionesSrv.getListArticulo(this.detall.id_articulo!)
      .subscribe(resp => {
        this.loading = false;
        
        console.log('_componentes/ubicaciones-articulo', resp)

        if (resp.error) {
          console.log(resp.mensaje);
        } else {
          this.ubiList = resp.data;
          if (!this.ubiList.length) this.avisoVacio = true;
        }
      })
  }


  public onRowUbiClick(rowEvent: any) {
    this.padre.onRowUbiClick(rowEvent);
  }

}
