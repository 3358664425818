<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<section id="wrapper" class="login-register login-sidebar"
    style="background-image:url(../assets/images/background/login-register.jpg);">
    <div class="login-box card">
        <div class="card-body">
            <form 
                class="form-horizontal form-material" 
                id="loginform" 
                autocomplete="off" 
                [formGroup]="regiterForm"
                (ngSubmit)="crearUsuario()">
                
                <a href="javascript:void(0)" class="text-center db"><img src="../assets/images/logo-icon.png"
                        alt="Home" /><br /><img src="../assets/images/logo-text.png" alt="Home" /></a>
                <h3 class="box-title m-t-40 m-b-0">Registro</h3><small>Crea tu cuenta aquí</small>
                <div class="form-group m-t-20">
                    <div class="col-xs-12">
                        <input formControlName="username" class="form-control" type="text" required=""
                            placeholder="Nombre">
                    </div>
                </div>
                <div class="form-group ">
                    <div class="col-xs-12">
                        <input formControlName="email" class="form-control" type="text" required="" placeholder="eMail">
                    </div>
                </div>
                <div class="form-group ">
                    <div class="col-xs-12">
                        <input formControlName="password" class="form-control" type="password" required=""
                            placeholder="password">
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-xs-12">
                        <input formControlName="password2" class="form-control" type="password" required=""
                            placeholder="Confirma el password">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <div class="checkbox checkbox-primary p-t-0">
                            <input formControlName="terminos" id="checkbox-signup" type="checkbox"
                                class="filled-in chk-col-light-blue">
                            <label for="checkbox-signup"> Estoy conforme con los <a href="#">Términos</a></label>
                        </div>
                    </div>
                </div>

                <div class="form-group text-center m-t-20">
                    <div class="col-xs-12">
                        <button class="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light"
                            type="submit">Registrarse</button>
                    </div>
                </div>
                <div class="form-group m-b-0">
                    <div class="col-sm-12 text-center">
                        <p>¿Ya tienes una cuenta?
                            <a routerLink="/login" class="text-info m-l-5">
                                <b>Entrar</b>
                            </a>
                        </p>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->