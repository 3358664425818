<div [class.oculto]="modalImagenSrv.ocultarModal"
     class="fondo-modal-imagen ">

    <div class="modal fade show"
         id="exampleModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel1"
         style="display: block;"
         (click)="cerrarModalClickOutside($event)">
        <div class="modal-dialog"
             role="document">

            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title"
                        id="exampleModalLabel1">Cargar imagen</h4>
                    <button type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close">
                        <span (click)="cerrarModal()"
                              aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">

                    <div class="text-center shadow">
                        <span *ngIf="loading">
                            <i class="fa fa-spin fa-refresh fa-2x mr-1"> </i><br>
                            Subiendo...
                        </span>
                        <span *ngIf="!loading">
                            <img [src]="modalImagenSrv.foto"
                                 loading="lazy"
                                 class="img-thumbnail img-usr mb-2">

                        </span>
                    </div>

                    <label></label>
                    <label class="custom-file d-block">
                        <input type="file"
                               class=""
                               [disabled]="loading"
                               (change)="subirFoto($event )"
                               value="">
                        <span class="custom-file-control">Selecciona imagen...</span>
                    </label>


                </div>

                <div class="modal-footer d-flex">

                    <button type="button"
                            *ngIf="modalImagenSrv.foto && !modalImagenSrv.foto.includes('_noFoto.png')"
                            [disabled]="loading"
                            (click)="borrarFoto()"
                            class="mr-auto btn waves-effect waves-light btn-rounded btn-danger">Borrar foto</button>

                    <button (click)="cerrarModal()"
                            [disabled]="loading"
                            class="ml-auto btn waves-effect waves-light btn-rounded btn-secondary"
                            data-dismiss="modal">Cancelar</button>
                </div>

            </div>
        </div>
    </div>


</div>