import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificacionesService } from '../notificaciones.service';
import { environment } from 'src/environments/environment';
import { IApiResp } from 'src/app/interfaces/api-resp';
import { catchError, throwError, Observable } from 'rxjs';
import { UsuarioService } from './usuario.service';
import { IEntrada, IEntradaDetallMEdidas } from 'src/app/interfaces/entrada';
import { IListaOpciones } from 'src/app/interfaces/lista-opciones';

@Injectable({
  providedIn: 'root'
})
export class EntradasService {

  apiURL: string = environment.apiURL;

  public entrada: IEntrada = {};
  public selected: any;    // Línea de la entrada seleccionada

  constructor(
    private http: HttpClient,
    private usuarioSrv: UsuarioService,
    private router: Router,
    private notificacionesSrv: NotificacionesService,
  ) { }


  getEntrada(id: number) {
    const path = `${this.apiURL}/entradas/list/${id}`;
    const resp = this.http.get<IApiResp>(path)
    return resp;
  }


  getLista(_opciones: IListaOpciones = {}) {
    // Devulve la lista filtrada
    const path = `${this.apiURL}/entradas/listFiltro`;

    // Filtrar parámetros de entrada para limpiar posibles valores antiguos
    const data = {
      id: _opciones.id || null,
      id_almacen: _opciones.id_almacen || null,
      id_cliente: _opciones.id_cliente || null,
      id_articulo: _opciones.id_articulo || null,
      id_ubicacion: _opciones.id_ubicacion || null,
      swCompleto: _opciones.swCompleto || null,
      swEnStock: _opciones.swEnStock || null,
      fechaDesde: _opciones.fechaDesde || null,
      fechaHasta: _opciones.fechaHasta || null,
    };

    return this.http.post<IApiResp>(path, data)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }



  new(formData: IEntrada) {
    const path = `${this.apiURL}/entradas/new`;
    return this.http.post<IApiResp>(path, formData)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          return throwError(() => new Error(error));
        })
      );
  }

  update(entrada: IEntrada) {
    const path = `${this.apiURL}/entradas/update/${entrada.id}`;

    return this.http.post<IApiResp>(path, entrada)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }


  updateMedidas(datos: IEntradaDetallMEdidas) {
    const path = `${this.apiURL}/entradas/updateMedidas`;

    return this.http.post<IApiResp>(path, datos)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }

  confirmarPda(datos: any) {
    const path = `${this.apiURL}/entradas/confirmarPda`;
    return this.http.post<IApiResp>(path, datos)
  }


  delete(id: number) {
    const path = `${this.apiURL}/entradas/delete/${id}`;
    return this.http.get<IApiResp>(path)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }



  creaPdf(datos: any) {
    // genera un PDF a partir de un html
    const path = `${this.apiURL}/entradas/entradaToPdf`;
    return this.http.post<IApiResp>(path, datos)
  }


  ///// AUX ////

  errorAuth() {
    this.usuarioSrv.logOut();
    console.log('error', environment.apiErrorAuth);
    // this.notificacionesSrv.aviso('error', environment.apiErrorAuth);
  }


}
