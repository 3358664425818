<div id="main-wrapper">
    <app-header></app-header>
    <app-sidebar></app-sidebar>

    <div class="page-wrapper">
        <div class="container-fluid">

            <!-- breadcrums-->
            <app-bead-crums></app-bead-crums>

            <!-- ============================================================== -->
            <!-- Start Page Content -->
            <!-- ============================================================== -->
            <router-outlet></router-outlet>
            <!-- ============================================================== -->
            <!-- End Page Content -->
            <!-- ============================================================== -->



        </div>

        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <footer class="footer">
            <b>Innova</b>Cen © {{ano}} Grupo ViaPack
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->


    </div>

</div>

<!-- modal para imágenes -->
<app-modal-imagen></app-modal-imagen>

<!-- modal para pdfs -->
<app-modal-pdf></app-modal-pdf>