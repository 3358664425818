import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Verificar si es una solicitud de inicio de sesión o un refreshToken
    if (request.url.includes('/login')) {
      return next.handle(request);
    }
    if (request.url.includes('/refreshToken')) {
      return next.handle(request);
    }
    

    // Agregar cabecera de autenticación para todas las demás solicitudes

    console.log("Add cabecera desde AuthInterceptor para:",request.urlWithParams);

    const token = localStorage.getItem('token') || '';
    const headers = request.headers.append('Authorization', 'Bearer ' + token);
    const authRequest = request.clone({ headers });
    return next.handle(authRequest);

  }
}
