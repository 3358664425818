import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IApiResp } from 'src/app/interfaces/api-resp';
import { catchError, throwError } from 'rxjs';
import { UsuarioService } from './usuario.service';
import { IListaOpciones } from 'src/app/interfaces/lista-opciones';
import { IPreparaciones } from 'src/app/interfaces/preparaciones';

@Injectable({
  providedIn: 'root'
})
export class PreparacionesService {

  apiURL: string = environment.apiURL;

  constructor(
    private http: HttpClient,
    private usuarioSrv: UsuarioService
  ) { }


  getLista(_opciones: IListaOpciones = {}) {
    // Devulve la lista filtrada
    const path = `${this.apiURL}/preparaciones/listFiltro`;

    const data = {
      id_almacen: _opciones.id_almacen || null,
      id_cliente: _opciones.id_cliente || null,
      id_articulo: _opciones.id_articulo || null,
      id_ubicacion: _opciones.id_ubicacion || null
    };

    return this.http.post<IApiResp>(path, data)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          return throwError(() => new Error(error));
        })
      )
  }


  getListaFiltrada(_opciones: IListaOpciones = {}) {
    // Devulve la lista filtrada
    const path = `${this.apiURL}/preparaciones/listFiltro`;

    const data = {
      id_almacen: _opciones.id_almacen || null,
      id_cliente: _opciones.id_cliente || null,
      id_articulo: _opciones.id_articulo || null,
      id_ubicacion: _opciones.id_ubicacion || null
    };

    return this.http.post<IApiResp>(path, data)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          return throwError(() => new Error(error));
        })
      )
  }




  getPreparacionPorCampanya(id: number) {
    // Devulve preparaciones de una campaña
    const path = `${this.apiURL}/preparaciones/listPorCampanya/${id}`;
    const resp = this.http.get<IApiResp>(path)
    return resp;
  }
  getPreparacionPorCampanyaAgrupado(id: number) {
    // Devulve preparaciones de una campaña 
    // agrupadas por id_articulo, uic (sumando sus cantidades)
    const path = `${this.apiURL}/preparaciones/listPorCampanya/${id}/1`;
    const resp = this.http.get<IApiResp>(path)
    return resp;
  }



  getListaContaRecogidas(id_usuario: number) {
    // Devulve lista con el número de RECOGIDAS pendientes que tiene un usuario usr_asignado 
    const path = `${this.apiURL}/preparaciones/getListaContaRecogidas/${id_usuario}`;
    const resp = this.http.get<IApiResp>(path)
    return resp;
  }

  getListContaPrepPedidosUsr(id_usuario: number) {
    // Devulve lista con el número de PREPRACIONES de pedidos pendientes que tiene un usuario usr_asignado 
    const path = `${this.apiURL}/preparaciones/listContaPrepPedidosUsr/${id_usuario}`;
    const resp = this.http.get<IApiResp>(path)
    return resp;
  }



  getListPrepUsr(id_usuario: number) {
    // Devulve las preparaciones pendientes asignadas a un usuario agrupadas por recogida_cod
    const path = `${this.apiURL}/preparaciones/listPrepUsr/${id_usuario}`;
    const resp = this.http.get<IApiResp>(path)
    return resp;
  }

  listRecogidaCod(data: any) {
    //POST Devulve las preparaciones pendientes de una recogida_cod
    //data(recogida_cod: string, todo: string)    
    const path = `${this.apiURL}/preparaciones/listRecogidaCod`;
    return this.http.post<IApiResp>(path, data)
  }


  delete(id: number) {
    // Elimina una preparación
    const path = `${this.apiURL}/preparaciones/delete/${id}`;
    const resp = this.http.get<IApiResp>(path)
    return resp;
  }


  asignaUsr(data: any) {
    // Asigna un usuario para la RECOGIDA a un grupo de registros de una preparacion
    const path = `${this.apiURL}/preparaciones/asignaUsr`;

    return this.http.post<IApiResp>(path, data)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          return throwError(() => new Error(error));
        })
      )
  }

  asignaUsrPreparacion(data: any) {
    // Asigna un usuario para la PREPARACION de pedidos a un grupo de registros de una preparacion
    // Crea los registros de la tabla reparto 
    const path = `${this.apiURL}/preparaciones/asignaUsrPreparacion`;

    return this.http.post<IApiResp>(path, data)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          return throwError(() => new Error(error));
        })
      )
  }



  updatePrep(data: any) {
    // Updae un registro preparacion
    const path = `${this.apiURL}/preparaciones/updatePrep`;
    return this.http.post<IApiResp>(path, data)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          return throwError(() => new Error(error));
        })
      )
  }

  carroLleno(id: number) {
    // Divide un registro preparacion en 2. con valores actuales y restantes
    const path = `${this.apiURL}/preparaciones/carroLleno/${id}`;
    return this.http.get<IApiResp>(path)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          return throwError(() => new Error(error));
        })
      )
  }



  saltarArticulo(data: any) {
    // Graba el motivo del salto
    const path = `${this.apiURL}/preparaciones/saltarArticulo`;
    return this.http.post<IApiResp>(path, data)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          return throwError(() => new Error(error));
        })
      )
  }



  buscaUbiAlternativa(preparacion: IPreparaciones) {
    // Buscar una ubicación alternativa a un registro preparación
    const path = `${this.apiURL}/preparaciones/buscaUbiAlternativa`;
    return this.http.post<IApiResp>(path, preparacion)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          return throwError(() => new Error(error));
        })
      )
  }

  cerrarPreparacion(preparacion: IPreparaciones) {
    // Cierre prematuro de una preparación
    const path = `${this.apiURL}/preparaciones/cerrarPreparacion`;
    return this.http.post<IApiResp>(path, preparacion)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          return throwError(() => new Error(error));
        })
      )
  }


}
