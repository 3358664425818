// Módulos
import { NgModule } from '@angular/core';
import { AuthRoutingModule } from './auth/auth.routing';
import { RouterModule, Routes } from '@angular/router';
import { PagesRoutingModule } from './pages/pages.routing';
import { PagesPdaRoutingModule } from './pages-pda/pages-pda.routing';

// Componentes
import { Page404Component } from './page404/page404.component';
import { AuthGuard } from './guards/auth.guard';
import { HomeComponent } from './home/home.component';
import { AdminGuard } from './guards/admin.guard';

// Desde home.ts se redirige en función del rol 

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: '**', component: Page404Component },

  // {
  //   path: 'pda',
  //   loadChildren: () => import('./pages-pda/pages-pda.routing').then(m => m.PagesPdaRoutingModule),
  //   canLoad: [AuthGuard], canActivate: [AuthGuard]
  // },
  // {
  //   path: 'panel',
  //   loadChildren: () => import('./pages/pages.routing').then(m => m.PagesRoutingModule),
  //   canLoad: [AuthGuard, AdminGuard], canActivate: [AuthGuard, AdminGuard]
  // },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true }),
    PagesRoutingModule,
    PagesPdaRoutingModule,
    AuthRoutingModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
