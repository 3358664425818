import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PagesPdaRoutingModule } from './pages-pda.routing';
import { PagesPdaComponent } from './pages-pda.component';

import { SharedModule } from '../shared/shared.module';
import { CabeceraComponent } from './comun/cabecera/cabecera.component';

import { InicioPdaComponent } from './inicio-pda/inicio-pda.component';
import { RouterModule } from '@angular/router';
import { PreparaRecogeComponent } from './prepara-recoge/prepara-recoge.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PreparaDetallComponent } from './prepara-detall/prepara-detall.component';
import { NgxMaskModule } from 'ngx-mask';
import { PreparaEnvioComponent } from './prepara-envio/prepara-envio.component';
import { PreparaEnvio2Component } from './prepara-envio2/prepara-envio2.component';
import { PreparaEnvio3Component } from './prepara-envio3/prepara-envio3.component';
import { UbicacionInfoComponent } from './ubicacion-info/ubicacion-info.component';
import { EanInfoComponent } from './ean-info/ean-info.component';
import { PedidosPackagingComponent } from './pedidos-packaging/pedidos-packaging.component';
import { ComponentesModule } from '../componentes/componentes.module';
import { EntradaArticulosComponent } from './entrada-articulos/entrada-articulos.component'; 


@NgModule({
  declarations: [
    PagesPdaComponent,
    CabeceraComponent,
    InicioPdaComponent,
    PreparaRecogeComponent,
    PreparaDetallComponent,
    PreparaEnvioComponent,
    PreparaEnvio2Component,
    PreparaEnvio3Component,
    UbicacionInfoComponent,
    EanInfoComponent,
    PedidosPackagingComponent,
    EntradaArticulosComponent, 
  ],
  imports: [
    RouterModule,
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentesModule,
    NgxMaskModule.forRoot(),

  ],
  exports: [
    CommonModule,
    PagesPdaRoutingModule
  ]
})
export class PagesPdaModule { }
