<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<section id="wrapper" class="error-page">
    <div class="error-box">
        <div class="error-body text-center">
            <h1>404</h1>
            <h3 class="text-uppercase">¡Página no encontrada!</h3>
            <p class="text-muted m-t-30 m-b-30">PARECE QUE ESTÁS TRATANDO DE ENCONTRAR SU CAMINO A CASA</p>
            <a href="index.html" class="btn btn-info btn-rounded waves-effect waves-light m-b-40">Ir al inicio</a>
        </div>
        <footer class="footer">
            <b>Innova</b>Cen © {{ano}} Grupo ViaPack
        </footer>
    </div>
</section>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->