import { Pipe, PipeTransform } from '@angular/core';
import * as Const from '../shared/constants';

@Pipe({
  name: 'uicLabelEstado'
})
export class UicLabelEstadoPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    // 1 disponible, 2 en uso, 3 caducado
    switch (value) {
      case Const.ESTADO_UIC_DISPONIBLE:
        return '<span class="text-success">Disponible</span>';
        break;
      case Const.ESTADO_UIC_EN_RECOGIDA:
        return '<span class="text-info">En recogida</span>';
        break;
      case Const.ESTADO_UIC_EN_PREPARACION:
        return '<span class="text-info">En preparación</span>';
        break;
      case Const.ESTADO_UIC_CADUCADO:
        return '<span class="text-danger">Caducado</span>';
        break;
      case Const.ESTADO_UIC_LLENO:
        return '<span class="text-warning">Carro lleno</span>';
        break;
      default:
        return '<span class="text-muted">Desconocido</span>';
    }
    return value;

  }
}
