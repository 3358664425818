import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioService } from '../services/datos/usuario.service';


///// NAVEGACIÓN POR DEFECTO según rol en el token /////

@Component({
  selector: 'app-home',
  templateUrl: 'app-home.html',
  styles: []
})


export class HomeComponent implements OnInit {

  profile: any = {};

  constructor(
    private router: Router,
    public usuarioSrv: UsuarioService
  ) { }


  ngOnInit() {

    this.usuarioSrv.getProfile()
      .subscribe(resp => {
        this.profile = resp.data.profile;

        console.log(' ___ this.profile=', this.profile)

        if (this.profile) {

          if (this.profile.role == "usuario") {
            this.router.navigateByUrl('pda');
          }

          if (this.profile.role == "admin") {
            this.router.navigateByUrl('panel');
          }

        } else {
          this.router.navigateByUrl('/login');
        }

      });

  }


}
