import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, } from '@angular/router';
import { UsuarioService } from '../services/datos/usuario.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AdminGuard implements CanActivate {
  private rolesAdministracion: string[] = environment.rolesAdministracion;

  constructor(private usuarioSrv: UsuarioService) { }


  // Solo se cargará cuando sea rol admin, lazzyLoad
  canLoad(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    const rol: string = this.usuarioSrv.userdata.rol!;

    if (this.rolesAdministracion.includes(rol)) return true;
    return false
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

    const rol: string = this.usuarioSrv.userdata.rol!;

    if (this.rolesAdministracion.includes(rol)) return true;

    // no tiene permiso para acceder aquí...
    this.usuarioSrv.logOut();
    return false
  }

}
