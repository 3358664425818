import * as Const from '../../shared/constants';

// módulos
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// interfaces
import { IStock } from 'src/app/interfaces/stock';

// servicios
import { StockService } from 'src/app/services/datos/stock.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';

// librerías
import FuncLib from 'src/app/providers/funcionesLib';
@Component({
  selector: 'app-ean-info',
  templateUrl: './ean-info.component.html',
  styleUrls: ['./ean-info.component.scss']
})
export class EanInfoComponent implements OnInit {

  // Usar constantes en el template
  Const = Const

  public eanForm: FormGroup = new FormGroup({});

  id_stock: string = '';
  public stock: IStock = {};

  stockList: IStock[] = [];

  public ean: string = ''; 

  public enviado: boolean = false;
  public loading: boolean = false; 

  constructor(
    private stockSrv: StockService,
    private fb: FormBuilder,
    private notificacionesService: NotificacionesService,
  ) { }


  ngOnInit(): void {

    // Definir el form de ean
    this.eanForm = this.fb.group({
      ean: ['', [Validators.required ]]
    })

  }

  enviar() {
    this.enviado = true;
    this.ean = this.eanForm.get('ean')?.value

    if (this.eanForm.invalid) {
      console.log("ERRORES:", this.eanForm);
      return;
    }

    const datos = {
      ean: this.ean
    }

    this.loading = true;
    this.stockSrv.getLista(datos).subscribe(resp => {
      this.loading = false;

      console.log('__enviar', resp);

      if (resp.error) {
        console.log(resp.mensaje);
        //  for (let controlName in resp.mensaje) {
        //   this.ubiForm.get(controlName)!.setErrors({ 'apiError': resp.mensaje[controlName] });
        //  }
        this.eanForm.controls['ean'].setErrors({ '': true });
        this.notificacionesService.aviso('error', resp.mensaje)

        this.stockList = []
        return
      }

      // todo ok
      this.stockList = resp.data
      return

    })


  }

  /// AUX ///

  get f() {
    return this.eanForm.controls;
  }

}
