export const environment = {
  production: true,
  entorno: '',
  timeOut: (60 * 60 * 1000),   // Milisegundos de inactividad que provocan la DESCONEXIÓN automática (1hora = 60minutos * 60 segundos * 1000 ms)
  timeRefreshCampanyas: 5000,  // Refesco automático de los datos de campañas. 5000 milisegundos = 5 segundos

  apiErrorAuth: 'El sistema ha devuelto un error. <br><i>Asegurate de estar autorizado para hacer esta operación</i>',
  defaultTema: 'default-dark.css',


  // URL absoluta
  url: 'https://clientes.innovacen.com',

  // URL de la API
  apiURL: 'https://api.innovacen.com/api',

  // Directorios
  fotoDir: 'https://api.innovacen.com/data/fotos',
  pdfDir: 'https://api.innovacen.com/data/pdfs',


  // roles
  rolesAdministracion: ['admin', 'sa']
};
