<div class="row animated fadeIn fast">

    <div class="col-12">
        <div class="card border rounded animated fadeIn fast">
            <div class="card-body">
                <form class="form p-t-20"
                      [formGroup]="formLabel"
                      (ngSubmit)="enviar()"
                      class="form-horizontal">
                    <div class="form-body">

                        <div class="row">

                            <!-- id_cliente -->
                            <div class="col-md-3 col-6">
                                <label class="form-control-label">Cliente:</label>
                                <div class="input-group border border-dark rounded">
                                    <div class="input-group-addon"><i class="fa-solid fa-user"></i></div>
                                    <select class="form-control"
                                            formControlName="id_cliente"
                                            (ngModelChange)="onClienteSelect($event)">
                                        <option value=''>Sin especificar</option>
                                        <option *ngFor="let cli of clientesList"
                                                [value]=cli.id>
                                            {{cli.nombre}}
                                        </option>
                                    </select>
                                </div>
                            </div>


                            <!-- cantidad -->
                            <!-- <div class="col-md-3">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': cantidad && f['cantidad'].errors }">
                                    <label class="form-control-label">Cantidad:</label>
                                    <input type="number"
                                           step='1'
                                           formControlName="cantidad"
                                           class="form-control"
                                           [ngClass]="{ 'form-control-danger': enviado && f['cantidad'].errors }">

                                    <div *ngIf="enviado && f['cantidad'].errors"
                                         class="form-control-feedback">
                                    </div>
                                    <small>Etiquetas</small>
                                </div>
                            </div> -->

                            <!-- cantidad -->
                            <div class="col-md-2 col-6">
                                <div class="form-group"
                                     [ngClass]="{ 'has-danger': enviado && f['cantidad'].errors }">
                                    <label class="form-control-label">Cantidad:</label>
                                    <select formControlName="cantidad"
                                            class="form-control"
                                            [ngClass]="{ 'form-control-danger': enviado && f['cantidad'].errors }">
                                        <option [value]="'1'">1</option>
                                        <option [value]="'2'">2</option>
                                        <option [value]="'3'">3</option>
                                        <option [value]="'4'">4</option>
                                        <option [value]="'5'">5</option>
                                        <option [value]="'6'">6</option>
                                        <option [value]="'7'">7</option>
                                        <option [value]="'8'">8</option>
                                        <option [value]="'9'">9</option>
                                        <option [value]="'10'">10</option>
                                        <option [value]="'15'">15</option>
                                        <option [value]="'20'">20</option>
                                        <option [value]="'30'">30</option>
                                    </select>
                                    <div *ngIf="enviado && f['cantidad'].errors"
                                         class="form-control-feedback">
                                        <div *ngIf="formLabel.get('cantidad')?.errors?.['apiError']">{{
                                            formLabel.get('cantidad')?.errors?.['apiError'] }}</div>
                                    </div>
                                    <small>
                                        <i>Etiquetas</i>
                                    </small>
                                </div>
                            </div>




                        </div>
                        <hr>


                        <div class="d-flex">
                            <h6 *ngIf="uicLabelList && uicLabelList.length > 0"
                                class="card-subtitle ml-4">
                                <li> Última etiqueta: {{ uicLabelList[0].uic }} <i [innerHTML]="uicLabelList[0].estado | uicLabelEstado"></i></li>
                                <li> {{ uicLabelList[0].cliNombre}}</li>
                            </h6>
                            <button [disabled]="loading"
                                    type="submit"
                                    class="ml-auto btn waves-effect waves-light btn-rounded btn-success">
                                <i *ngIf="loading"
                                   class="fa fa-spinner fa-spin"></i>
                                Imprimir</button>
                        </div>


                    </div>
                </form>


            </div>
        </div>
    </div>


    <div *ngIf="uicLabelList && uicLabelList.length > 0"
         class="col-12 d-none d-md-block ">

        <div class="card border rounded animated fadeIn fast">
            <div class="card-body">
                <p class="text-info">Últimos UIC's</p>

                <div class="row">
                    <div class="col-12">

                        <table class="table table-striped table-sm">
                            <thead>
                                <tr>
                                    <th>UIC</th>
                                    <th>Cliente</th>
                                    <th>Estado</th>
                                    <th>Estado el</th>
                                    <th>Creada hace</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let uic of uicLabelList">
                                    <td>{{ uic.uic }}</td>
                                    <td>{{ uic.cliNombre }}</td>
                                    <td [innerHTML]="uic.estado | uicLabelEstado"></td>
                                    <td>{{ uic.updated_at | date:'dd/MM/yyyy HH:mm'}}</td>
                                    <td>{{ uic.created_at! | friendlyDdmmyy}}</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>


                <div class="row">
                    <div class="col-12">
                        <div class="d-flex">
                            <h6 *ngIf="uicLabelList && uicLabelList.length > 0"
                                class="card-subtitle ml-4">
                                <p> Últimos {{ limiteRegistros}} de {{totalRegistros}} UIC's</p>

                                <div class="progress">
                                    <div class="progress-bar bg-success"
                                         role="progressbar"
                                         [style.width.%]="(limiteRegistros / totalRegistros) * 100"
                                         [attr.aria-valuenow]="(limiteRegistros / totalRegistros) * 100"
                                         aria-valuemin="0"
                                         aria-valuemax="100"></div>
                                </div>
                            </h6>
                            <button *ngIf="limiteRegistros < totalRegistros"
                                    [disabled]="loading"
                                    (click)="cargaMas()"
                                    type="button"
                                    class="ml-auto btn waves-effect waves-light btn-rounded btn-success">
                                <i *ngIf="loading "
                                   class="fa fa-spinner fa-spin"></i>
                                Cargar más
                            </button>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>