<div class="row page-titles">
    <div class="col-md-5 align-self-center">
        <h3 class="text-themecolor">Resumen de la preparación:</h3>
        <h3 class="text-themecolor">{{recogida_cod}}</h3>
        <h6>{{totalUbis | number: '0.0-0':'es'}} Recogidas</h6>
    </div>
</div>



<!-- 
    <div class="col-lg-12"
     style="padding: 1px">
    <div class="card">

        <div *ngIf="preparacionesList"
             class="card-body">
            <table class="table table-sm">
                <thead>
                    <tr>
                        <th>EAN</th>
                        <th>Cant.</th>
                        <th>Recg.</th>
                        <th>Ubicación</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let recPrep of preparacionesList">
                        <td>{{recPrep.id_articulo}}</td>
                        <td class="text-right">{{recPrep.cant_reserva | number: '0.0-0':'es'}}</td>
                        <td class="text-right">{{recPrep.cant_recogida | number: '0.0-0':'es'}}</td>
                        <td>{{recPrep.ubicacion!.toString() | mask: '00.00.00.00.00.00'}}</td>
                    </tr>
                </tbody>
            </table>


        </div>

    </div>
</div> 
-->





<div class="col-lg-12"
     style="padding: 1px">

    <div class="card"
         *ngFor="let uic of getPreparacionesAgrupadasKeys()">

        <h5 class="text-success"> <i class="fa-solid fa-dolly"></i> {{ uic }}</h5>
        <table class="table table-sm">
            <thead>
                <tr>
                    <th>EAN</th>
                    <th>Sku</th>
                    <th class="text-right">Cant.</th>
                    <th class="text-right">Recg.</th>
                    <th>Ubicación</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let recPrep of preparacionesAgrupadas[uic]"
                    [ngClass]="{'bg-warning': recPrep.estado=='8'}">
                    <td>{{ recPrep.ean }}</td>
                    <td>{{ recPrep.sku }}</td>
                    <td class="text-right">
                        <ng-container *ngIf="recPrep.cant_inicial == 0">
                            {{ recPrep.cant_reserva | number: '0.0-0':'es' }}
                        </ng-container>
                        <ng-container *ngIf="recPrep.cant_inicial != 0">
                            {{ recPrep.cant_inicial | number: '0.0-0':'es' }}
                        </ng-container>
                    </td>
                    <td class="text-right">{{ recPrep.cant_recogida | number: '0.0-0':'es' }}</td>
                    <td>{{ recPrep.ubicacion! | mask: '00.00.00.00.00.00' }}</td>
                </tr>
            </tbody>
        </table>

    </div>

</div>


<button *ngIf="!loading"
        type="button"
        (click)="volverClick()"
        class="btn waves-effect waves-light btn-rounded btn-success btn-sm">
    Volver</button>