import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../guards/auth.guard';
import { PagesPdaComponent } from './pages-pda.component';
import { InicioPdaComponent } from './inicio-pda/inicio-pda.component';

import { PreparaRecogeComponent } from './prepara-recoge/prepara-recoge.component';
import { PreparaDetallComponent } from './prepara-detall/prepara-detall.component';
import { PreparaEnvioComponent } from './prepara-envio/prepara-envio.component';
import { PreparaEnvio2Component } from './prepara-envio2/prepara-envio2.component';
import { PreparaEnvio3Component } from './prepara-envio3/prepara-envio3.component';

import { LocalConfigComponent } from '../shared/local-config/local-config.component';
import { UsrPerfilComponent } from '../shared/usr-perfil/usr-perfil.component';
import { UicLabelsComponent } from '../shared/uic-labels/uic-labels.component';
import { UicInfoComponent } from '../shared/uic-info/uic-info.component';
import { UbicacionInfoComponent } from './ubicacion-info/ubicacion-info.component';
import { EanInfoComponent } from './ean-info/ean-info.component';

import { PedidosPackagingComponent } from './pedidos-packaging/pedidos-packaging.component';

import { EntradaArticulosComponent } from './entrada-articulos/entrada-articulos.component';
import { EntradaUbicaArticuloComponent } from '../pages/gestion/entrada-ubica-articulo/entrada-ubica-articulo.component';

const routes: Routes = [

  // {
  //   path: 'pda',
  //   component: PagesPdaComponent,   // Usar el componente de plantilla común
  //   children: [    
  //     {
  //       path: 'pda/inicio_pda', component: PreparacionesListComponent,
  //       canActivate: [AuthGuard], canLoad: [AuthGuard],
  //       data: { titulo: 'Preparaciones pendientes' }
  //     },
  //   ],
  // },



  {
    path: 'pda', component: PagesPdaComponent,
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    children: [
      {
        path: '', component: InicioPdaComponent,
        canActivate: [AuthGuard], canLoad: [AuthGuard]
      },
      {
        path: 'local_config', component: LocalConfigComponent,
        canActivate: [AuthGuard], canLoad: [AuthGuard]
      },
      {
        path: 'usr_config', component: UsrPerfilComponent,
        canActivate: [AuthGuard], canLoad: [AuthGuard]
      },
      {
        path: 'uic-labels', component: UicLabelsComponent,
        canActivate: [AuthGuard], canLoad: [AuthGuard]
      },
      {
        path: 'ubicacion-info', component: UbicacionInfoComponent,
        canActivate: [AuthGuard], canLoad: [AuthGuard]
      },
      {
        path: 'ean-info', component: EanInfoComponent,
        canActivate: [AuthGuard], canLoad: [AuthGuard]
      },

      {
        path: 'uic-info', component: UicInfoComponent,
        canActivate: [AuthGuard], canLoad: [AuthGuard]
      },

      {
        path: 'inicio_pda', component: InicioPdaComponent,
        canActivate: [AuthGuard], canLoad: [AuthGuard]
      }, {
        path: 'prepara_recoge/:recogida_cod', component: PreparaRecogeComponent,
        canActivate: [AuthGuard], canLoad: [AuthGuard]
      }, {
        path: 'prepara_detall/:recogida_cod', component: PreparaDetallComponent,
        canActivate: [AuthGuard], canLoad: [AuthGuard]
      }, {
        path: 'prepara_envio/:uic', component: PreparaEnvioComponent,
        canActivate: [AuthGuard], canLoad: [AuthGuard]
      }, {
        path: 'prepara_envio2/:uic', component: PreparaEnvio2Component,
        canActivate: [AuthGuard], canLoad: [AuthGuard]
      }, {
        path: 'prepara_envio3/:uic/:id_artic', component: PreparaEnvio3Component,
        canActivate: [AuthGuard], canLoad: [AuthGuard]
      },

      // packaging
      {
        path: 'pedidos_list_pack/:id_campanya', component: PedidosPackagingComponent,
        canActivate: [AuthGuard], canLoad: [AuthGuard]
      },


      // ubicar entradas
      {
        path: 'entrada_ubicar_articulo/:id/:id_detall', component: EntradaUbicaArticuloComponent, data: { titulo: 'Ubicar un artículo en almacén' },
        canActivate: [AuthGuard], canLoad: [AuthGuard]
      },
      {
        path: 'entrada_articulos/:id_entrada', component: EntradaArticulosComponent, data: { titulo: 'Ubicar artículos de una entrada' },
        canActivate: [AuthGuard], canLoad: [AuthGuard]
      }

    ]

  }







];

@NgModule({
  imports: [RouterModule.forChild(routes)],      // Menú forChild
  exports: [RouterModule]
})

export class PagesPdaRoutingModule { }
