import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ModalPdfService {

  pdfDir: string = environment.pdfDir;
  private _ocultarModal: boolean = true;

  public tipo!: 'albaranEntrada' | 'docCliente';
  public id!: string;
  public pdf!: string;

  constructor(
  ) { }

  // Declarar EventEmitter 'nuevoPdf' para poder Emitir un evento si se cambia el pdf
  public nuevoPdf: EventEmitter<any> = new EventEmitter<any>();

  get ocultarModal() {
    return this._ocultarModal;
  }

  abrirModal(tipo: 'albaranEntrada' | 'docCliente', id: string, pdfNombre: string) {
    this._ocultarModal = false;
    this.tipo = tipo;
    this.id = id;
    this.pdf = `${this.pdfDir}/${tipo}/${pdfNombre}`;
  }

  cerrarModal() {
    this._ocultarModal = true;
  }



}
