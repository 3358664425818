<!-- recogidas pendientes -->
<div class="accordion"
     id="accordion1">

    <div class="card">
        <div class="card-header"
             [ngClass]="{'bg-success': preparacionesList && preparacionesList.length > 0}"
             id="heading_1_1">
            <h2 class="mb-0">
                <button class="btn btn-link no-underline"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapse_1_1"
                        aria-expanded="false"
                        aria-controls="collapse_1_1">
                    <div class="row page-titles"
                         [ngClass]="{'bg-success': preparacionesList && preparacionesList.length > 0}">
                        <h3 class="text-themecolor"> {{preparacionesList.length| i18nPlural: recogidasPluralMap}} </h3>
                    </div>
                </button>
            </h2>
        </div>

        <div id="collapse_1_1"
             class="collapse"
             aria-labelledby="heading_1_1"
             data-parent="#accordion1"
             [hidden]="!preparacionesList || preparacionesList.length === 0">

            <div class="card-body">
                <div class="col-lg-12">
                    <div class="card">
                        <div *ngFor="let prep of preparacionesList">
                            <div class="card-body">
                                <a [routerLink]="'/pda/prepara_recoge/' + prep.recogida_cod"
                                   class="btn btn-info btn-block"
                                   [ngClass]="{'bg-warning': prep.campanyaTipo === '1 a 1'}">
                                    {{prep.recogida_cod}}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>















<!-- preparaciones pendientes -->
<div class="accordion"
     id="accordion2">
    <div class="card">
        <div class="card-header"
             [ngClass]="{'bg-success': preparacionesList2 && preparacionesList2.length > 0}"
             id="heading_2_1">
            <h2 class="mb-0">
                <button class="btn btn-link no-underline"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapse_2_1"
                        aria-expanded="false"
                        aria-controls="collapse_2_1">
                    <div class="row page-titles"
                         [ngClass]="{'bg-success': preparacionesList2 && preparacionesList2.length > 0}">
                        <h3 class="text-themecolor"> {{preparacionesList2.length| i18nPlural: preparacionesPluralMap}} </h3>
                    </div>
                </button>
            </h2>
        </div>

        <div id="collapse_2_1"
             class="collapse"
             aria-labelledby="heading_2_1"
             data-parent="#accordion2"
             [hidden]="!preparacionesList2 || preparacionesList2.length === 0">

            <div class="card-body">
                <div class="col-lg-12">
                    <div class="card">
                        <div *ngFor="let prep of preparacionesList2">
                            <div class="card-body">
                                <a [routerLink]="'/pda/prepara_envio/' + prep.uic"
                                   class="btn btn-info btn-block"
                                   [ngClass]="{'bg-warning': prep.campanyaTipo === '1 a 1'}">
                                    {{prep.camp_cod}} {{prep.uic}}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>









<!-- Campañas sin embalar -->
<div class="accordion"
     id="accordion3">
    <div class="card">
        <div class="card-header"
             [ngClass]="{'bg-success': campanyasSinEmbalarList && campanyasSinEmbalarList.length > 0}"
             id="heading_3_1">
            <h2 class="mb-0">
                <button class="btn btn-link no-underline"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapse_3_1"
                        aria-expanded="false"
                        aria-controls="collapse_3_1">
                    <div class="row page-titles"
                         [ngClass]="{'bg-success': campanyasSinEmbalarList && campanyasSinEmbalarList.length > 0}">
                        <h3 class="text-themecolor"> {{campanyasSinEmbalarList.length| i18nPlural: campanyasPluralMap}} </h3>
                    </div>
                </button>
            </h2>
        </div>

        <div id="collapse_3_1"
             class="collapse"
             aria-labelledby="heading_3_1"
             data-parent="#accordion3"
             [hidden]="!campanyasSinEmbalarList || campanyasSinEmbalarList.length === 0">

            <div class="card-body">
                <div class="col-lg-12">
                    <div class="card">
                        <div *ngFor="let campanya of campanyasSinEmbalarList">
                            <div class="card-body">
                                <a [routerLink]="'/pda/pedidos_list_pack/' + campanya.id"
                                   class="btn btn-info btn-block"
                                   [ngClass]="{'bg-warning': campanya.campanyaTipo === '1 a 1'}">
                                    {{campanya.camp_cod}}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>






<!-- Entradas sin ubicar fisicamente -->
<div class="accordion"
     id="accordion4">
    <div class="card">
        <div class="card-header"
             [ngClass]="{'bg-success': entradasNoUbicadasList && entradasNoUbicadasList.length > 0}"
             id="heading_4_1">
            <h2 class="mb-0">
                <button class="btn btn-link no-underline"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapse_4_1"
                        aria-expanded="false"
                        aria-controls="collapse_4_1">
                    <div class="row page-titles"
                         [ngClass]="{'bg-success': entradasNoUbicadasList && entradasNoUbicadasList.length > 0}">
                        <h3 class="text-themecolor"> {{entradasNoUbicadasList.length| i18nPlural: entradasPluralMap}} </h3>
                    </div>
                </button>
            </h2>
        </div>

        <div id="collapse_4_1"
             class="collapse"
             aria-labelledby="heading_4_1"
             data-parent="#accordion4"
             [hidden]="!entradasNoUbicadasList || entradasNoUbicadasList.length === 0">

            <div class="card-body">
                <div class="col-lg-12">
                    <div class="card">
                        <div *ngFor="let entrada of entradasNoUbicadasList">
                            <div class="card-body">
                                <a [routerLink]="'/pda/entrada_articulos/' + entrada.id"
                                   class="btn btn-info btn-block">
                                    {{entrada.ubiPlaya | mask: '00.00.00.00.00.00'}} - {{entrada.cliNombre}} - {{entrada.albaran}}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>