<!-- ============================================================== -->
<!-- Topbar header - style you can find in pages.scss -->
<!-- ============================================================== -->
<header class="topbar">
    <nav class="navbar top-navbar navbar-expand-md navbar-light">
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <div class="navbar-header">
            <a class="navbar-brand"
               href="./">
                <!-- Logo icon --><b>
                    <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
                    <!-- Dark Logo icon -->
                    <img src="./assets/images/logo-icon.png"
                         alt="inicio"
                         class="dark-logo" /> </b>

                <!--End Logo icon -->
                <!-- Logo text --><span>
                    <!-- dark Logo text -->
                    <img src="./assets/images/logo-text.png"
                         alt="inicio"
                         class="dark-logo" />
                    <!-- Light Logo text -->
                    <img src="./assets/images/logo-light-text.png"
                         class="light-logo"
                         alt="inicio" /></span>
            </a>
        </div>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->



        <div class="navbar-collapse">

            <!-- Usuario conectado -->
            <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                    <span>
                        <i class="fa-solid fa-user mb-1 text-info"
                           style="font-size: 1.2rem;"></i> 
                        {{usuarioSrv.userdata.username | uppercase}}
                    </span>
                </li>
            </ul>

            <!-- ============================================================== -->
            <!-- toggle and nav items -->
            <!-- ============================================================== -->
            <ul class="navbar-nav mr-auto">
                <!-- This is  -->

                <li class="nav-item"> <a class="nav-link sidebartoggler hidden-sm-down waves-effect waves-dark"
                       href="javascript:void(0)"></a> </li>
                <li class="nav-item hidden-sm-down"></li>
            </ul>



            <!-- ============================================================== -->
            <!-- User profile and search -->
            <!-- ============================================================== -->
            <ul class="navbar-nav my-lg-0">

                <!-- ============================================================== -->
                <!-- Profile -->
                <!-- ============================================================== -->
                <li class="nav-item dropdown mr-2">
                    <a class="nav-link dropdown-toggle waves-effect waves-dark"
                       href=""
                       data-toggle="dropdown"
                       aria-haspopup="true"
                       aria-expanded="false">
                        <i class="fa-solid fa-bars fa-xl"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right animated fadeIn">
                        <ul class="dropdown-user">
                            <li>
                                <div class="dw-user-box">
                                    <div class="u-img">
                                        <img [src]="getUserFoto()"
                                             alt="usuario">
                                    </div>

                                    <div class="u-text">
                                        <h4>{{usuarioSrv.userdata.nombre | slice:0:15}}</h4>
                                        <p class="text-muted">{{usuarioSrv.userdata.rol}}</p>
                                        <a routerLink="./usr_config"
                                           class="btn btn-rounded btn-danger btn-sm">
                                            Ver Perfil
                                        </a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="u-text">
                                    <h6 *ngIf="usuarioSrv.userdata.timestamp"
                                        class="m-2">Última conexión hace {{usuarioSrv.userdata.timestamp! | friendlyTimestamp}}</h6>
                                </div>
                            </li>

                            <li role="separator"
                                class="divider">
                            </li>

                            <li>
                                <a routerLink="/pda/inicio_pda">
                                    <i class="fa-solid fa-house"></i> Inicio
                                </a>
                            </li>

                            <li>
                                <a routerLink="/pda/uic-labels">
                                    <i class="fa-solid fa-tags"></i> Etiquetas UIC
                                </a>
                            </li>
                            <li>
                                <hr class="list-divider">
                            </li>
                            <li>
                                <a routerLink="/pda/uic-info">
                                    <i class="fa-solid fa-dolly"></i> Info UIC
                                </a>
                            </li>
                            <li>
                                <a routerLink="/pda/ubicacion-info">
                                    <i class="fa-solid fa-location-pin"></i> Info Ubicación
                                </a>
                            </li>
                            <li>
                                <a routerLink="/pda/ean-info">
                                    <i class="fa-solid fa-boxes-stacked"></i> Info Ean
                                </a>
                            </li>
                            <li>
                                <hr class="list-divider">
                            </li>




                            <li>
                                <a routerLink="local_config">
                                    <i class="ti-settings"></i> Configurar App
                                </a>
                            </li>
                            <li *ngIf="!pantallacompleta">
                                <a (click)="goFullScreen()">
                                    <i class="fa-solid fa-expand"></i> Pantalla completa
                                </a>
                            </li>
                            <li *ngIf="pantallacompleta">
                                <a (click)="exitFullScreen()">
                                    <i class="fa-solid fa-compress"></i> Salir de pantalla completa
                                </a>
                            </li>

                            <li role="separator"
                                class="divider">
                            </li>
                            <li>
                                <a (click)="logOut()"
                                   class="pointer">
                                    <i class="fa fa-power-off text-danger"></i> Desconectar
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>




            <!-- ============================================================== -->
            <!-- User profile and search -->
            <!-- ============================================================== -->





        </div>
    </nav>
</header>
<!-- ============================================================== -->
<!-- End Topbar header -->
<!-- ============================================================== -->