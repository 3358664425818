import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-local-config',
  templateUrl: './local-config.component.html',
  styleUrls: ['./local-config.component.scss']
})
export class LocalConfigComponent implements OnInit {
  public linkTheme = document.querySelector('#theme');

  menuExtendido: boolean = (localStorage.getItem('menuExtendido') === 'true');
  public summaryPosition: string = localStorage.getItem('summaryPosition') || 'bottom';
  public rowsLimitStr: string = localStorage.getItem('linTablas') || '10';
  imgResized: string = localStorage.getItem('imgResized') || '/resize/';

  // Voces
  public vozCajetin: boolean = localStorage.getItem('vozCajetin') === '1';
  public vozPale: boolean = localStorage.getItem('vozPale') === '1';

  public vozError: boolean = (localStorage.getItem('vozError') === '1');
  public vozSuccess: boolean = (localStorage.getItem('vozSuccess') === '1');
  public vozInfo: boolean = (localStorage.getItem('vozInfo') === '1');
  public vozWarning: boolean = (localStorage.getItem('vozWarning') === '1');

  constructor(private configService: ConfigService) { }

  ngOnInit(): void {
    this.configService.marcaAcivo();
  }

  cambiaColorTema(tema: string) {
    this.configService.cambiaColorTema(tema);
  }

  cambiaMenu() {
    this.configService.menuExtendido(this.menuExtendido);
  }

  cambiaSummaryPosition() {
    this.configService.summaryPosition(this.summaryPosition);
  }

  cambiaImgResized() {
    this.configService.imgResized(this.imgResized);
  }


  rowsLimitChange() {
    this.configService.rowsLimitChange(this.rowsLimitStr);
  }


  // Voces:

  cambiaVozCajetin() { this.configService.vozCajetin(this.vozCajetin) }
  cambiaVozPale() { this.configService.vozPale(this.vozPale) }

  cambiaVozError() { this.configService.vozError(this.vozError) }
  cambiaVozSuccess() { this.configService.vozSuccess(this.vozSuccess) }
  cambiaVozInfo() { this.configService.vozInfo(this.vozInfo) }
  cambiaVozWarning() { this.configService.vozWarning(this.vozWarning) }

}
