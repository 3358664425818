<!-- ============================================================== -->
<!-- Topbar header - style you can find in pages.scss -->
<!-- ============================================================== -->
<header class="topbar">
    <nav class="navbar top-navbar navbar-expand-md navbar-light">
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <div class="navbar-header">
            <a class="navbar-brand"
               href="./">
                <!-- Logo icon --><b>
                    <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
                    <!-- Dark Logo icon -->
                    <img src="./assets/images/logo-icon.png"
                         alt="inicio"
                         class="dark-logo" />
                    <!-- Light Logo icon -->
                    <img src="./assets/images/logo-light-icon.png"
                         alt="inicio"
                         class="light-logo" />
                </b>
                <!--End Logo icon -->
                <!-- Logo text --><span>
                    <!-- dark Logo text -->
                    <img src="./assets/images/logo-text.png"
                         alt="inicio"
                         class="dark-logo" />
                    <!-- Light Logo text -->
                    <img src="./assets/images/logo-light-text.png"
                         class="light-logo"
                         alt="inicio" /></span> </a>
        </div>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <div class="navbar-collapse">
            <!-- ============================================================== -->
            <!-- toggle and nav items -->
            <!-- ============================================================== -->
            <ul class="navbar-nav mr-auto">
                <!-- This is  -->
                <li class="nav-item">
                    <a id="menuTgl"
                       class="nav-link nav-toggler hidden-md-up waves-effect waves-dark"
                       href="javascript:void(0)">
                        <i class="ti-menu"></i>
                    </a>
                </li>
                <li class="nav-item"> <a class="nav-link sidebartoggler hidden-sm-down waves-effect waves-dark"
                       href="javascript:void(0)"><i class="ti-menu"></i></a> </li>
                <li class="nav-item hidden-sm-down"></li>
            </ul>
            <!-- ============================================================== -->
            <!-- User profile and search -->
            <!-- ============================================================== -->
            <ul class="navbar-nav my-lg-0">


                <!-- ============================================================== -->
                <!-- Comment -->
                <!-- ============================================================== -->

                <!-- ============================================================== -->
                <!-- End Comment -->
                <!-- ============================================================== -->

                <!-- ============================================================== -->
                <!-- Messages -->
                <!-- ============================================================== -->

                <!-- 
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle waves-effect waves-dark"
                       href=""
                       id="2"
                       data-toggle="dropdown"
                       aria-haspopup="true"
                       aria-expanded="false">
                        <i class="mdi mdi-email"></i>
                        <div class="notify">
                            <span class="heartbit"></span>
                            <span class="point"></span>
                        </div>
                    </a>
                    <div class="dropdown-menu mailbox dropdown-menu-right animated fadeIn"
                         aria-labelledby="2">
                        <ul>
                            <li>
                                <div class="drop-title">Tienes 4 mensajes nuevos</div>
                            </li>
                            <li>
                                <div class="message-center">
                                    
                                    <a href="#">
                                        <div class="user-img">
                                            <img src="./assets/images/users/gls_2.jpg"
                                                 alt="user"
                                                 class="img-circle">
                                            <span class="profile-status online pull-right"></span>
                                        </div>
                                        <div class="mail-contnet">
                                            <h5>CRM Hospitalet</h5>
                                            <span class="mail-desc">Almacen 01, ocupación al 98%</span>
                                            <span class="time">9:30 AM</span>
                                        </div>
                                    </a>
                                    

                                    <a href="#">
                                        <div class="user-img">
                                            <img src="./assets/images/users/gls_2.jpg"
                                                 alt="user"
                                                 class="img-circle">
                                            <span class="profile-status busy pull-right"></span>
                                        </div>
                                        <div class="mail-contnet">
                                            <h5>Oleada pendiente</h5>
                                            <span class="mail-desc">Preparación oleada 35987 Pendiente</span>
                                            <span class="time">9:10 AM</span>
                                        </div>
                                    </a>
                                    

                                    <a href="#">
                                        <div class="user-img">
                                            <img src="./assets/images/users/diotroni.png"
                                                 alt="user"
                                                 class="img-circle">
                                            <span class="profile-status away pull-right"></span>
                                        </div>
                                        <div class="mail-contnet">
                                            <h5>Oleada pendiente</h5>
                                            <span class="mail-desc">Preparación oleada 25344 Pendiente</span>
                                            <span class="time">9:10 AM</span>
                                        </div>
                                    </a>
                                    

                                    <a href="#">
                                        <div class="user-img">
                                            <img src="./assets/images/users/diotroni.png"
                                                 alt="user"
                                                 class="img-circle">
                                            <span class="profile-status offline pull-right"></span>
                                        </div>
                                        <div class="mail-contnet">
                                            <h5>CRM Hospitalet</h5>
                                            <span class="mail-desc">Dejar playa libre, entrada Jansen</span>
                                            <span class="time">9:02 AM</span>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li>
                                <a class="nav-link text-center"
                                   href="javascript:void(0);">
                                    <strong>Ver todos </strong>
                                    <i class="fa fa-angle-right"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li> 
                -->
                <!-- ============================================================== -->
                <!-- End Messages -->
                <!-- ============================================================== -->


                <li class="nav-item dropdown">
                    <span>{{entorno}}</span>
                </li>

                <!-- ============================================================== -->
                <!-- Language -->
                <!-- ============================================================== -->
                <!-- <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle waves-effect waves-dark"
                       href=""
                       data-toggle="dropdown"
                       aria-haspopup="true"
                       aria-expanded="false">
                        <i class="fa-solid fa-warehouse"></i> Hospitalet
                    </a>
                    <div class="dropdown-menu dropdown-menu-right animated fadeIn">
                        <a class="dropdown-item"
                           href="javascript:void(0);">
                            <i class="fa fa-warehouse"></i> Almacén 2
                        </a>
                        <a class="dropdown-item"
                           href="javascript:void(0);">
                            <i class="fa fa-warehouse"></i> Almacén 3
                        </a>
                        <a class="dropdown-item"
                           href="javascript:void(0);">
                            <i class="fa fa-warehouse"></i> Almacén 4
                        </a>
                    </div>
                </li> -->
                <!-- ============================================================== -->
                <!-- Profile -->
                <!-- ============================================================== -->
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle waves-effect waves-dark"
                       href=""
                       data-toggle="dropdown"
                       aria-haspopup="true"
                       aria-expanded="false"><img [src]="getUserFoto()"
                             alt="usuario"
                             class="profile-pic" /></a>
                    <div class="dropdown-menu dropdown-menu-right animated fadeIn">
                        <ul class="dropdown-user">
                            <li>
                                <div class="dw-user-box">
                                    <div class="u-img">
                                        <img [src]="getUserFoto()"
                                             alt="usuario">
                                    </div>
                                    <div class="u-text">
                                        <h4>{{usuarioSrv.userdata.nombre | slice:0:15}}</h4>
                                        <p class="text-muted">{{usuarioSrv.userdata.rol}}</p>
                                        <a routerLink="./usr_perfil"
                                           class="btn btn-rounded btn-danger btn-sm">
                                            Ver Perfil
                                        </a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="u-text">
                                    <h6 *ngIf="usuarioSrv.userdata.timestamp"
                                        class="m-2">Última conexión hace {{usuarioSrv.userdata.timestamp! | friendlyTimestamp}}</h6>
                                </div>
                            </li>
                            <li role="separator"
                                class="divider"></li>
                            <li><a href="#"><i class="ti-email"></i> Mensajes</a></li>
                            <li><a routerLink="config"><i class="ti-settings"></i> Configurar App</a></li>
                            <li role="separator"
                                class="divider"></li>
                            <li><a (click)="logOut()"
                                   class="pointer">
                                    <i class="fa fa-power-off text-danger"></i> Desconectar
                                </a>
                            </li> 
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</header>
<!-- ============================================================== -->
<!-- End Topbar header -->
<!-- ============================================================== -->