import { Pipe, PipeTransform } from '@angular/core';

/**
 * Si la fecha es anterior a mañana, añade un icono de Warning
 */

@Pipe({
  name: 'iconFechaCaducada'
})
export class iconFechaCaducadaPipe implements PipeTransform {
  transform(value: any): string {
    if (!value) {
      return value;
    }
    const fecha = new Date(value);
    const hoy = new Date();
    const manana = new Date();
    manana.setDate(manana.getDate() + 1);
    if (fecha < manana) {
      return (`${value} <i title="Caducado" class="text-warning ml-1 fa-solid fa-triangle-exclamation"></i>`)
    }
    return value;
  }
}
