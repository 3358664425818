import { Component, HostListener } from '@angular/core';
import { InactividadService } from './services/inactividad.service';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { UsuarioService } from './services/datos/usuario.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ng_almacen';

  constructor(
    public usuarioSrv: UsuarioService,
    private inactivitySrv: InactividadService,
  ) {
    this.inactivitySrv.onInactivity().subscribe((inactive) => {
      if (inactive) {
        // Se ha detectado inactividad
        console.log('Usuario inactivo. Cerrando sesión...', this.usuarioSrv.userdata.id);
        if (this.usuarioSrv.userdata.id) {
          // Si existe usuario logueado, mostrar aviso
          Swal.fire({
            title: "Tu sesión se ha cerrado por inactividad",
            html: `<b>${this.usuarioSrv.userdata.username}</b> Por seguridad, tu sesión se ha cerrado. <br>Vuelve a identificarte.`,
            icon: "info"
          });
        }

      }
    });
  }

}
