
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';

// Módulos
import { RouterModule } from '@angular/router';
import { SharedModule } from './../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentesModule } from './../componentes/componentes.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgxMaskModule } from 'ngx-mask';
import { NgChartsModule } from 'ng2-charts';


// Componentes
import { PanelComponent } from './panel/panel.component';
import { PagesComponent } from './pages.component';

import { ArticulosListaComponent } from './maestros/articulos-lista/articulos-lista.component';
import { ArticuloEditComponent } from './maestros/articulo-edit/articulo-edit.component';
import { StockListComponent } from './maestros/stock-list/stock-list.component';
import { AlmacenesComponent } from './tablas/almacenes/almacenes.component';
import { TransaccionesComponent } from './tablas/transacciones/transacciones.component';
import { AlmacenComponent } from './tablas/almacen/almacen.component';
import { InfoComponent } from './utils/info/info.component';
import { EntradasListaComponent } from './gestion/entradas-lista/entradas-lista.component';
import { EntradaEditComponent } from './gestion/entrada-edit/entrada-edit.component';
import { EntradaUbicaPaleComponent } from './gestion/entrada-ubica-pale/entrada-ubica-pale.component';
import { EntradaUbicaArticuloComponent } from './gestion/entrada-ubica-articulo/entrada-ubica-articulo.component';
import { UbiTipoComponent } from './tablas/ubi-tipo/ubi-tipo.component';
import { UbiTiposComponent } from './tablas/ubi-tipos/ubi-tipos.component';
import { Ubicaciones2Component } from './tablas/ubicaciones2/ubicaciones2.component';
import { UbicacionesComponent } from './tablas/ubicaciones/ubicaciones.component';
import { UbicacionComponent } from './tablas/ubicacion/ubicacion.component';
import { EntradasUbicadasListaComponent } from './gestion/entradas-ubicadas-lista/entradas-ubicadas-lista.component';
import { StockMoverComponent } from './maestros/stock-mover/stock-mover.component';

import { ClientePedidosListaComponent } from './maestros/clientes/cliente-pedidos-lista/cliente-pedidos-lista.component';
import { ClientesListaComponent } from './maestros/clientes/clientes-lista/clientes-lista.component';
import { ClienteEditComponent } from './maestros/clientes/cliente-edit/cliente-edit.component';
import { ClienteEditfacComponent } from './maestros/clientes/cliente-editfac/cliente-editfac.component';
import { CampanyasListComponent } from './campanyas/campanyas-list/campanyas-list.component';
import { CampanyaNewComponent } from './campanyas/campanya-new/campanya-new.component';
import { PreparacionNewComponent } from './campanyas/preparacion-new/preparacion-new.component';
import { PreparacionesListaComponent } from './_componentes/preparaciones-lista/preparaciones-lista.component';
import { PreparacionesIncidenciasListComponent } from './preparaciones-incidencias-list/preparaciones-incidencias-list.component';
import { AsignarPedidosComponent } from './campanyas/asignar-pedidos/asignar-pedidos.component';
import { PedidoDetallComponent } from './_componentes/pedido-detall/pedido-detall.component';

import { UsrListaComponent } from './usuarios/usr-lista/usr-lista.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { StockListHistoricoComponent } from './maestros/stock-list-historico/stock-list-historico.component';
import { FacturacionComponent } from './facturacion/facturacion.component';
import { FacturacionSalidasComponent } from './facturacion/_components/salidas/salidas.component';
import { FacturacionEntradasComponent } from './facturacion/_components/entradas/entradas.component';
import { SegurosComponent } from './facturacion/_components/seguros/seguros.component';
import { ClienteFacListaComponent } from './maestros/clientes/cliente-fac-lista/cliente-fac-lista.component';
import { DevolucionesComponent } from './facturacion/_components/devoluciones/devoluciones.component';
import { AlmacenajeComponent } from './facturacion/_components/almacenaje/almacenaje.component';
import { StockMoverArticuloComponent } from './maestros/stock-mover-articulo/stock-mover-articulo.component';


@NgModule({
    declarations: [
        PanelComponent,
        PagesComponent,
        UsrListaComponent,
        ArticulosListaComponent,
        ClientesListaComponent,
        ArticuloEditComponent,
        ClienteEditComponent,
        ClienteEditfacComponent,
        StockListComponent,
        AlmacenesComponent,
        TransaccionesComponent,
        AlmacenComponent,
        InfoComponent,
        EntradasListaComponent,
        EntradaEditComponent,
        Ubicaciones2Component,
        EntradaUbicaPaleComponent,
        EntradaUbicaArticuloComponent,
        UbiTipoComponent,
        UbiTiposComponent,
        UbicacionesComponent,
        UbicacionComponent,
        EntradasUbicadasListaComponent,
        StockMoverComponent,
        ClientePedidosListaComponent,
        CampanyasListComponent,
        CampanyaNewComponent,
        PreparacionNewComponent,
        PreparacionesListaComponent,
        PreparacionesIncidenciasListComponent,
        AsignarPedidosComponent,
        PedidoDetallComponent,
        StockListHistoricoComponent,
        FacturacionComponent,
        FacturacionSalidasComponent,
        FacturacionEntradasComponent,
        SegurosComponent,
        ClienteFacListaComponent,
        DevolucionesComponent,
        AlmacenajeComponent,
        StockMoverArticuloComponent,
    ],
    exports: [
        PanelComponent,
        PagesComponent,
        UsrListaComponent,
        ArticulosListaComponent,

    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    imports: [
        SharedModule,

        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        ComponentesModule,
        NgxMaskModule.forRoot(),
        NgSelectModule,
        NgOptionHighlightModule,
        NgChartsModule,
        NgxDatatableModule,
    ]
})
export class PagesModule { }
