import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UsuarioService } from './datos/usuario.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InactividadService {

  timeOut: number = environment.timeOut;

  private timeoutDuration = this.timeOut; //Milisegundos de inactividad (60000ms)
  private userActivityTimeout: any;
  private userActivityEvents = ['mousemove', 'click', 'keydown', 'scroll', 'touchstart'];
  private inactivity$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public usuarioSrv: UsuarioService,
  ) {
    this.startWatching();
  }

  // Iniciar monitoreo de actividad
  private startWatching() {
    this.resetTimeout();
    this.userActivityEvents.forEach((eventName) => {
      window.addEventListener(eventName, () => this.resetTimeout());
    });
  }

  // Reiniciar temporizador
  private resetTimeout() {
    if (this.userActivityTimeout) {
      clearTimeout(this.userActivityTimeout);
    }
    this.userActivityTimeout = setTimeout(() => this.handleInactivity(), this.timeoutDuration);
  }

  // Manejar inactividad
  private handleInactivity() {
    this.inactivity$.next(true);
    this.logout();
  }

  // Logout por inactividad
  private logout() {
    // Limpiar token de autenticación
    this.usuarioSrv.logOut();

  }

  // Observable que permite detectar cuándo ocurre la inactividad
  onInactivity(): Observable<boolean> {
    return this.inactivity$.asObservable();
  }
}
