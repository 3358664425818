import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IPedido } from 'src/app/interfaces/pedido';
import { ModalFactSalidaService } from 'src/app/services/modal-fact-salida.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';

@Component({
  selector: 'app-modal-fact-salida',
  templateUrl: './modal-fact-salida.component.html',
  styleUrls: ['./modal-fact-salida.component.scss']
})
export class ModalFactSalidaComponent implements OnInit, OnDestroy {

  @Input() pedido?: IPedido = {};
  enviado: boolean = false;
  loading: boolean = false;

  public factForm: FormGroup = new FormGroup({});
  embalaSubscription: Subscription | undefined;


  constructor(
    public modalFactSalidaSrv: ModalFactSalidaService,
    private notificacionesSrv: NotificacionesService,
    private fb: FormBuilder) { }

  ngOnDestroy(): void {
    this.embalaSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.pedido = this.modalFactSalidaSrv.pedido;

    this.factForm = this.fb.group({

      id: [this.pedido?.id],
      fac_pack_horas: [this.pedido?.fac_pack_horas],

      fac_pack_bolsa01: [this.pedido?.fac_pack_bolsa01],
      fac_pack_bolsa02: [this.pedido?.fac_pack_bolsa02],
      fac_pack_bolsa03: [this.pedido?.fac_pack_bolsa03],

      fac_pack_caja01: [this.pedido?.fac_pack_caja01],
      fac_pack_caja02: [this.pedido?.fac_pack_caja02],
      fac_pack_caja03: [this.pedido?.fac_pack_caja03],

      fac_pack_burbuja: [this.pedido?.fac_pack_burbuja],
      fac_pack_viruta: [this.pedido?.fac_pack_viruta],
      fac_pack_papel: [this.pedido?.fac_pack_papel],
      fac_pack_otros: [this.pedido?.fac_pack_otros],

      fac_pack_pale01: [this.pedido?.fac_pack_pale01],
      fac_pack_pale02: [this.pedido?.fac_pack_pale02],
      fac_pack_pale03: [this.pedido?.fac_pack_pale03],
      fac_pack_pale04: [this.pedido?.fac_pack_pale04],
      fac_pack_pale05: [this.pedido?.fac_pack_pale05],
    })


    // Suscribirse al objeto y actualizar el formulario
    this.embalaSubscription = this.modalFactSalidaSrv.pedidoSubject.subscribe((pedido: IPedido) => {
      if (!pedido) return
      this.pedido = pedido

      console.log('__embalaSubscription pedido', this.pedido)
      this.factForm.patchValue({
        id: pedido?.id,
        fac_pack_horas: pedido?.fac_pack_horas,

        fac_pack_bolsa01: pedido?.fac_pack_bolsa01,
        fac_pack_bolsa02: pedido?.fac_pack_bolsa02,
        fac_pack_bolsa03: pedido?.fac_pack_bolsa03,

        fac_pack_caja01: pedido?.fac_pack_caja01,
        fac_pack_caja02: pedido?.fac_pack_caja02,
        fac_pack_caja03: pedido?.fac_pack_caja03,

        fac_pack_burbuja: pedido?.fac_pack_burbuja,
        fac_pack_viruta: pedido?.fac_pack_viruta,
        fac_pack_papel: pedido?.fac_pack_papel,
        fac_pack_otros: pedido?.fac_pack_otros,

        fac_pack_pale01: pedido?.fac_pack_pale01,
        fac_pack_pale02: pedido?.fac_pack_pale02,
        fac_pack_pale03: pedido?.fac_pack_pale03,
        fac_pack_pale04: pedido?.fac_pack_pale04,
        fac_pack_pale05: pedido?.fac_pack_pale05,
      });
      this.enviado = false
    });

  }


  cerrarModal() {
    this.modalFactSalidaSrv.pedido = undefined;
    this.modalFactSalidaSrv.cerrarModal();
  }

  cerrarModalClickOutside(event: MouseEvent) {
    // Cerrar modal al hacer click fuera del modal  
    if ((event.target as HTMLElement).classList.contains('modal')) {
      // this.cerrarModal();
    }
  }



  get f() {
    return this.factForm.controls;
  }


  enviar() {
    this.enviado = true;
    if (this.factForm.invalid) {
      console.log("ERRORES:", this.factForm);
      return;
    }
    // Todo ok, enviar al BackEnd
    this.loading = true;
    this.modalFactSalidaSrv.updateEmbalajes(this.factForm.value)
      .subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje);
          for (let controlName in resp.mensaje) {
            this.factForm.get(controlName)!.setErrors({ 'apiError': resp.mensaje[controlName] });
          }
        } else {
          this.notificacionesSrv.aviso('success', resp.mensaje);
          this.cerrarModal();
          return
        }
      })


  }


  ///   AUX   ///



}
