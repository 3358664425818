<div class="row page-titles">
    <div class="col-md-5 align-self-center">
        <h3 class="text-themecolor">Peparar envío</h3>
        <h3 class="text-themecolor">
            <i class="fa-solid fa-dolly"></i> {{uic}}
        </h3>
    </div>
</div>


<div class="row animated fadeIn fast">
    <div class="col-lg-12">
        <div class="card">


            <div class="card-body">

                <form class="form"
                      [ngClass]="{'form-horizontal': true}">
                    <!-- PRIMERA parte del form, pedir uic -->
                    <div class="row">

                        <!-- uic -->
                        <div class="col-md-4">
                            <div class="form-group"
                                 [ngClass]="{ 'has-danger': uicError }">
                                <label class="form-control-label"> Valida UIC:</label>
                                <input type="text"
                                       autofocus
                                       name="uicInput"
                                       [(ngModel)]="uicInput"
                                       class="form-control"
                                       maxlength="13"
                                       (ngModelChange)="validarLongitudUIC($event)">

                                <div *ngIf="uicError"
                                     class="form-control-feedback">
                                    <div>UIC incorrecto</div>
                                </div>

                            </div>
                        </div>

                    </div> <!-- row -->
                </form>

            </div>


        </div> <!-- card -->
    </div> <!-- col-lg-12 -->
</div>