import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificacionesService } from '../notificaciones.service';
import { environment } from 'src/environments/environment';
import { IApiResp } from 'src/app/interfaces/api-resp';
import { catchError, throwError } from 'rxjs';
import { UsuarioService } from './usuario.service';
import { IArticulo } from 'src/app/interfaces/articulo';

@Injectable({
  providedIn: 'root'
})
export class AlmacenesService {

  apiURL: string = environment.apiURL;

  constructor(
    private http: HttpClient,
    private usuarioSrv: UsuarioService,
    private router: Router,
    private notificacionesSrv: NotificacionesService,
  ) { }

  // Cabecera HttpHeaders Authorization
  // get headers() {
  //   const token = localStorage.getItem('token') || '';
  //   const cabeceras = new HttpHeaders().append('Authorization', 'Bearer ' + token);
  //   return { headers: cabeceras }
  // }

  getAlmacen(id: number) {
    const path = `${this.apiURL}/almacenes/list/${id}`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  }

  getLista() {
    const heders = true;
    const path = `${this.apiURL}/almacenes/list`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  }


  new(formData: any) {
    const path = `${this.apiURL}/almacenes/new`;
    return this.http.post<IApiResp>(path, formData)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      );
  }

  update(articulo: IArticulo) {
    const path = `${this.apiURL}/almacenes/update/${articulo.id}`;

    return this.http.post<IApiResp>(path, articulo)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }

  delete(id: number) {
    const path = `${this.apiURL}/almacenes/delete/${id}`;
    return this.http.get<IApiResp>(path)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }

  ///// AUX ////

  errorAuth() {
    this.usuarioSrv.logOut();
    console.log('error', environment.apiErrorAuth);
    // this.notificacionesSrv.aviso('error', environment.apiErrorAuth);
  }


}
