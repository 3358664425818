import * as Const from '../../shared/constants';

// módulos
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// interfaces
import { IStock } from 'src/app/interfaces/stock';

// servicios
import { StockService } from 'src/app/services/datos/stock.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';

// librerías
import FuncLib from 'src/app/providers/funcionesLib';


@Component({
  selector: 'app-ubicacion-info',
  templateUrl: './ubicacion-info.component.html',
  styleUrls: ['./ubicacion-info.component.scss']
})
export class UbicacionInfoComponent implements OnInit {

  // Usar constantes en el template
  Const = Const

  public ubiForm: FormGroup = new FormGroup({});

  id_stock: string = '';
  public stock: IStock = {};

  stockList: IStock[] = [];

  public codUbi: string = '';
  id_ubicacion: number | null = null;

  public enviado: boolean = false;
  public loading: boolean = false;

  private funcLib: FuncLib = new FuncLib();
  public codUbiDecript: string = '';

  constructor(
    private stockSrv: StockService,
    private fb: FormBuilder,
    private notificacionesService: NotificacionesService,
  ) { }


  ngOnInit(): void {

    // Definir el form de ubicación
    this.ubiForm = this.fb.group({
      codUbi: ['', [Validators.required, Validators.minLength(12), Validators.maxLength(12)]]
    })

  }

  enviar() {
    this.enviado = true;
    this.codUbi = this.ubiForm.get('codUbi')?.value

    if (this.ubiForm.invalid) {
      console.log("ERRORES:", this.ubiForm);
      return;
    }

    const datos = {
      codUbi: this.codUbi
    }

    this.loading = true;
    this.stockSrv.getLista(datos).subscribe(resp => {
      this.loading = false;

      console.log('__enviar', resp);

      if (resp.error) {
        console.log(resp.mensaje);
        //  for (let controlName in resp.mensaje) {
        //   this.ubiForm.get(controlName)!.setErrors({ 'apiError': resp.mensaje[controlName] });
        //  }
        this.ubiForm.controls['codUbi'].setErrors({ '': true });
        this.notificacionesService.aviso('error', resp.mensaje)

        this.stockList = []
        return
      }

      // todo ok
      this.stockList = resp.data
      return

    })


  }

  /// AUX ///


  public cambiaUbi() {
    const ubi = this.ubiForm.get('codUbi')?.value;
    this.codUbiDecript = this.funcLib.CodUbiDecript(ubi);
    console.log('__cambiaUbi()', this.codUbiDecript)
  }

  get f() {
    return this.ubiForm.controls;
  }

}
