import { Component, Input, OnInit } from '@angular/core';
import { IEntrada } from 'src/app/interfaces/entrada';

@Component({
  selector: 'app-entrada-info',
  templateUrl: './entrada-info.component.html',
  styleUrls: ['./entrada-info.component.scss']
})
export class EntradaInfoComponent implements OnInit {

  @Input() entrada: IEntrada = {};

  constructor() { }

  ngOnInit(): void {
  }

}
