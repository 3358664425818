// componentes
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// interfaces y modelos
import { ICampanya } from 'src/app/interfaces/campanya';
import { IPreparaciones } from 'src/app/interfaces/preparaciones';
import { IReparto } from 'src/app/interfaces/reparto';
import { IUicLabel } from 'src/app/interfaces/uicLabel';

// servicios
import { ClientesService } from 'src/app/services/datos/clientes.service';
import { RepartosService } from 'src/app/services/datos/repartos.service';
import { UicService } from 'src/app/services/datos/uic.service';
import { UsuarioService } from 'src/app/services/datos/usuario.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';

// librerias y entorno
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-uic-info',
  templateUrl: './uic-info.component.html',
  styleUrls: ['./uic-info.component.scss']
})
export class UicInfoComponent implements OnInit {

  public enviado: boolean = false;
  public loading: boolean = false;

  public fotoDir: string = environment.fotoDir;
  public imgResized: string = localStorage.getItem('imgResized') || '/resize/';

  public uicLabel: IUicLabel = {};
  public campanyaInfo: ICampanya = {};

  public listPreparacion: IPreparaciones[] = [];
  public listReparto: IReparto[] = [];
  public listUicsPedido: any[] = [];
  public listUics: any[] = [];

  public uicTotales: any = {};

  public formInfo: FormGroup = new FormGroup({});

  constructor(
    private fb: FormBuilder,
    private notificacionesSrv: NotificacionesService,
    public uicSrv: UicService,
    public clientesSrv: ClientesService,
    private usuarioSrv: UsuarioService,
    private RepartoSrv: RepartosService,

  ) { }

  ngOnInit(): void {
    this.formInfo = this.fb.group({
      uic: ['', Validators.required],
      id_usuario: [this.usuarioSrv.userdata.id]
    })
  }

  enviar() {
    this.enviado = true;

    // Validar UIC

    const uicControl = this.formInfo.get('uic')?.value.toUpperCase();
    this.formInfo.patchValue({ uic: uicControl });
    const uicRegex = /^UIC\d{10}$/;  // 'UIC' seguido de 10 dígitos

    if (!uicRegex.test(uicControl)) {
      this.formInfo.get('uic')?.setErrors({ formatoIncorrecto: true });
      return;
    }

    // info de valUic
    this.loading = true;
    this.uicSrv.valUic(uicControl)
      .subscribe(async resp => {
        this.loading = false;
        console.log('__valUic resp', resp);
        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje);
          this.formInfo.get('uic')?.setErrors({ apiError: resp.mensaje });
          this.uicLabel = {}
          return;
        }

        this.uicLabel = resp.data;
        console.log('__uicLabel', this.uicLabel);
      });


    // info de uicInfo
    this.loading = true;
    this.RepartoSrv.getUicInfo(uicControl)
      .subscribe(async resp => {
        this.loading = false;
        console.log('__valUic getUicInfo resp', resp);
        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje);
          return;
        }

        this.listPreparacion = resp.data.listPreparacion;
        this.listReparto = resp.data.listReparto;
        this.listUicsPedido = resp.data.listUicsPedido;

        // Obtener array con todos los UIC sin repetir  
        const uicsUnicosSet = new Set(this.listUicsPedido.map(item => item.uic));
        const uicsUnicosArray = Array.from(uicsUnicosSet).filter(uic => uic && uic.trim() !== ''); // Filtrar elementos vacíos
        this.listUics = uicsUnicosArray.sort();

        this.uicTotales = resp.totales;
        this.campanyaInfo = resp.totales.campanya;

        console.log('__listPreparacion', this.listPreparacion);
        console.log('__listReparto', this.listReparto);
        console.log('__listUicsPedido', this.listUicsPedido);
        console.log('__listUics', this.listUics);
        console.log('__uicTotales', this.uicTotales);
        console.log('__campanyaInfo', this.campanyaInfo);

      });

  }


  seleccionarUIC(uicSeleccionado: string) {
    this.formInfo.patchValue({ uic: uicSeleccionado });
    this.enviar();
  }

  //// Aux  ///


  get f() {
    return this.formInfo.controls;
  }
}
