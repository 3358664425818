import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

// servicios
import { NotificacionesService } from '../notificaciones.service';
import { UsuarioService } from './usuario.service';

// interfaces
import { IApiResp } from 'src/app/interfaces/api-resp';
import { catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UicService {

  apiURL: string = environment.apiURL;

  constructor(
    private http: HttpClient,
    private usuarioSrv: UsuarioService,
    private router: Router,
    private notificacionesSrv: NotificacionesService,
  ) { }

  getLista(limite: number) {
    // Devulve la lista de los últimos x registros
    const path = `${this.apiURL}/uic/list/${limite}`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  }


  new(formData: any) {
    const path = `${this.apiURL}/uic/new`;
    return this.http.post<IApiResp>(path, formData)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      );
  }



  valUic(uic: string) {
    // Devulve un uic
    const path = `${this.apiURL}/uic/valUic/${uic}`;
    const rec = this.http.get<IApiResp>(path)
    return rec;
  }

  valUicPreparacion(data: any) {
    // Valida si se puede usar un UIC en una preparación

    console.log('__ SERVICIO valUicPreparacion() data', data)
    


    const path = `${this.apiURL}/uic/valUicPreparacion`;
    return this.http.post<IApiResp>(path, data)
  }



  updateUicCliente(data: any) {
    // Asigna un UIC al cliente
    const path = `${this.apiURL}/uic/updateUicCliente`;
    return this.http.post<IApiResp>(path, data)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      );
  }




  ///// AUX ////

  errorAuth() {
    this.usuarioSrv.logOut();
    console.log('error', environment.apiErrorAuth);
    // this.notificacionesSrv.aviso('error', environment.apiErrorAuth);
  }

}
