import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uicEstado'
})
export class UicEstadoPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    // 1- No existe, 2- Recogiendo, 3- Completo, 8 Incidencia
    switch (value) {
      case '1':
        return '';
        break;
      case '2':
        return '<span class="text-info">Recogiendo</span>';
        break;
      case '3':
        return '<span class="text-success">Completo</span>';
        break;
      case '8':
        return '<span class="text-danger">Incidencia</span>';
        break;
      default:
        return '<span class="text-muted">Desconocido</span>';
    }
    return value;

  }

}
