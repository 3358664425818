import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare function inicializarCustomJs(): void;

/**
 * Servicio para recuperar la configuración de usuario
 */

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public linkTheme = document.querySelector('#theme');
  public icoMenu = document.getElementsByClassName('ti-menu');

  constructor() {
    // Cargar el tema desde localStorage

    const tema = localStorage.getItem('tema') || `./assets/css/colors/${environment.defaultTema}`;
    this.linkTheme?.setAttribute('href', tema);
  }


  cambiaColorTema(tema: string) {
    const url = `./assets/css/colors/${tema}.css`;
    this.linkTheme?.setAttribute('href', url);
    localStorage.setItem('tema', url);
    this.marcaAcivo();
  }

  marcaAcivo(): void {
    // Le pone la clase 'working' al activo
    const links = document.querySelectorAll('.selector');
    links.forEach(elemento => {
      elemento.classList.remove('working');
      const opcion = elemento.getAttribute('data-theme')!;
      const opcionUrl: string = `./assets/css/colors/${opcion}.css`;
      if (opcionUrl === this.linkTheme?.getAttribute('href')) elemento.classList.add('working');
    })
  }


  menuExtendido(extend: boolean) {
    if (extend) localStorage.setItem('menuExtendido', 'true');
    if (!extend) localStorage.setItem('menuExtendido', 'false');

    // inicializar los plugIn's y demás js del tema
    inicializarCustomJs();
  }


  summaryPosition(position: string) {
    localStorage.setItem('summaryPosition', position);
  }

  rowsLimitChange(linTablas: string) {
    localStorage.setItem('linTablas', linTablas);
  }

  imgResized(imgResized: string) {
    localStorage.setItem('imgResized', imgResized);
  }

  // setItem Voces:
  vozError(voz: boolean = false) { localStorage.setItem('vozError', voz ? '1' : '0') }
  vozSuccess(voz: boolean = false) { localStorage.setItem('vozSuccess', voz ? '1' : '0') }
  vozInfo(voz: boolean = false) { localStorage.setItem('vozInfo', voz ? '1' : '0') }
  vozWarning(voz: boolean = false) { localStorage.setItem('vozWarning', voz ? '1' : '0') }

  vozCajetin(voz: boolean = false) { localStorage.setItem('vozCajetin', voz ? '1' : '0') }
  vozPale(voz: boolean = false) { localStorage.setItem('vozPale', voz ? '1' : '0') }

}

