import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IArtPreparacion } from 'src/app/interfaces/artPreparacion';

// Servicios
import { PreparacionesService } from 'src/app/services/datos/preparaciones.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';


interface IPreparacionesAgrupadas {
  [uic: string]: IArtPreparacion[];  // Aquí asumí que IArtPreparacion es el tipo de tus elementos
}


@Component({
  selector: 'app-prepara-detall',
  templateUrl: './prepara-detall.component.html',
  styleUrls: ['./prepara-detall.component.scss']
})
export class PreparaDetallComponent implements OnInit {

  loading: boolean = false;
  recogida_cod: string = '';
  public preparacionesList: IArtPreparacion[] = [];
  public preparacionesAgrupadas: IPreparacionesAgrupadas = {};


  public totalUbis: number = 0;

  constructor(
    private prepracionSrv: PreparacionesService,
    private notificacionesSrv: NotificacionesService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {

    // Suscribirse a los parámetros de la url, envían una id, cargar los datos del articulo
    this.activatedRoute.params.subscribe(({ recogida_cod }) => {
      this.recogida_cod = recogida_cod;
      this.cargaPreparacionList();
    })
  }


  cargaPreparacionList() {
    this.loading = true;


    const data = {
      recogida_cod: this.recogida_cod || null,
      todo: '1'
    };

    this.prepracionSrv.listRecogidaCod(data)
      .subscribe(resp => {
        this.loading = false;
        console.log('__cargaPreparacionList() listaPreparaciones', resp);
        if (resp.error) this.notificacionesSrv.aviso('error', resp.mensaje);
        this.preparacionesList = resp.data;
        this.totalUbis = resp.totales.ubis;

        this.preparacionesAgrupadas = this.agruparPorUIC();
        console.log('__cargaPreparacionList() preparacionesAgrupadas', this.preparacionesAgrupadas);

      })
  }

  private agruparPorUIC(): { [key: string]: any[] } {
    const agrupadas: { [key: string]: any[] } = {};

    for (const preparacion of this.preparacionesList) {
      let uic = preparacion.uic;

      // Si uic está vacío, asigna una clave especial
      if (!uic) {
        uic = 'Incidencias';
      }

      if (!agrupadas[uic]) agrupadas[uic] = [];
      agrupadas[uic].push(preparacion);
    }


    // Obtiene las claves (UIC) y las ordena alfabéticamente
    const uicKeys = Object.keys(agrupadas).sort();

    // Devielve el objeto ordenado por UIC 
    const agrupadasOrdenadas: { [key: string]: any[] } = {};
    for (const key of uicKeys) {
      agrupadasOrdenadas[key] = agrupadas[key];
    }

    return agrupadasOrdenadas;

  }

  /// AUX  ///

  public getPreparacionesAgrupadasKeys(): string[] {
    // Getter del key
    return Object.keys(this.preparacionesAgrupadas);
  }

  volverClick() {
    this.router.navigate(['./pda']);
    return
  }


} 